<template>
  <div class="dynamic-page-container" v-if='!loading && renderSections && renderSections.length > 0'>
    <!-- <Particles 
      id="tsparticles"
      :options='particlesOPtions'
    /> -->
    <!-- {{width}}  -->
    <div class="">
      <div class="fixed-container">
        <div 
          v-for='(section, index) in renderSections'
          :key='index'
          class="fixed-div switch" 
          :id="index"
        >
        <!-- {{renderSections}} -->
          <!-- {{section}} -->
          <div 
            class="row "
            :class="'h-100'"
            v-if='section.style != null && section.style.type == "pageCover"'  
          >
            <div class="col-12 align-self-start">
              <BreadcrumbBanner :section='section' :pageName="$route.name" />
              <div class="page-sections fade-in" v-if='false'>
                <h4
                  class="project-year my-auto pointer underline-eff"
                  v-for="(name, nameInd) in pageSections"
                  :key="nameInd"
                  @click="scrollTo(name)"
                >
                  {{ name }}
                </h4>
              </div>
              <div
                class="description-text text-left mb-5 pb-5 more-padding mt-5 fade-in"
                v-if='$route.name == "Foundations"'
                v-html='section.description'
              >
              </div>
            </div>
          </div>
          <Banner 
            v-else-if='section.style != null && section.style.type == "slider"'
            :scrollIndex="scrollIndex"
            :width="width"
            :section='section'
            :secId='secId'
          />
          <ExploreMore
            v-else-if='section.style != null && section.style.type == "exploreMore"'
            class="h-100"
            :scrollIndex="scrollIndex"
            :width="width"
            :section='section'
            :sectionIndex='index'
          />
          <StrataFoundations
            class="h-100"
            v-else-if='section.style != null && section.style.type == "strataFoundations"'
            :scrollIndex="scrollIndex"
            :width="width"
            :section='section'
          />
          <Testmonials
            class="h-100"
            v-else-if='section.style != null && section.style.type == "testmonials"'
            :scrollIndex="scrollIndex"
            :width="width"
            :section='section'
          />
          <MapCanvas
            v-else-if='section.style != null && section.style.type == "map"'
            :changeForceScroll="changeForceScroll"
            :openModal="openModal"
            class="slide-in h-100"
            :width="width"
            :section='section'
          />
          <FoundationsPlan 
            :section='section'
            class="row h-100"
            v-else-if='section.style != null && section.style.type == "foundationsPlan"'  
          />
          <SoilSystemPlan 
            :section='section'
            class="row h-100"
            v-else-if='section.style != null && section.style.type == "aboutStrata"'  
          />
          <div 
            class="row h-100"
            v-else-if='section.style != null && section.style.type == "foundationsprojects"'  
          >
            <div class="col-12 align-self-center">
              <StrataProjects
                :section='section'
                class="mt-4"
                :projectsTitle="'Foundations'"
                :openModal="openModal"
                :closeModal="closeModal"
              />
            </div>
          </div>
          <div 
            class="row h-100"
            v-else-if='section.style != null && section.style.type == "soilprojects"'  
          >
            <div class="col-12 align-self-center">
              <StrataProjects
                :section='section'
                class="mt-4"
                :projectsTitle="'Soil Systems'"
                :openModal="openModal"
                :closeModal="closeModal"
              />
            </div>
          </div>
          <div 
            class="h-100 row justify-content-center"
            v-else
          >
            <div class="col-12 text-center align-self-center">
              <h5>{{section.title}}</h5>
              <div class="" v-html="section.description"></div>
            </div>
          </div>

        </div>
        
        <div   
          class="fixed-div switch" 
          :id='renderSections.length'
          :scrollIndex="scrollIndex"
        >
          <!-- <div class="row explore-more p-0">
            <div class="col-12 align-self-end">
              <ContactPanels class="my-3"/> 
              <NewsletterForm class="" />
              <Partners class="mt-0" :width="width" />
              <AppFooter
                :changeForceScroll="changeForceScroll"
                :width="width"
              />
            </div>
          </div> -->
          <div class="row explore-more px-0">
            <div class="col-12 align-self-end">
              <NewsletterForm class="" />
              <Partners v-if='$route.name == "Home"' class="mt-0" :width="width" />
              <AppFooter :changeForceScroll="changeForceScroll" :width="width" />
            </div>
          </div>
        </div>
      </div>
      <Scrollama
        :offset="0.5"
        class="pb-news"
        @step-progress="progressHandler"
        id="scrollhandlerContainer"
      >
        <div 
          v-for='(section, index) in renderSections'
          :key='index'
          class="body-protect-view" 
          :id="`section-${index}`"
        ></div>
        <div class="body-protect-view" :id="`section-${renderSections.length}`"></div>
        <!-- <div class="body-protect-view" id="section-1"></div>
        <div class="body-protect-view" id="section-2"></div>
        <div class="body-protect-view" id="section-3"></div>
        <div class="body-protect-view" id="section-4"></div> -->
        <!-- <div class="body-protect-view" id='section-5'>
              </div> -->
      </Scrollama>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import AppHeader from "@/components/Navigation/AppHeader.vue";
import Banner from "@/components/Banner.vue";
import ExploreMore from "@/components/ExploreMore.vue";
import StrataFoundations from "@/components/StrataFoundations.vue";
import Testmonials from "@/components/Testmonials.vue";
import Partners from "@/components/Partners.vue";
import StrataProjects from "@/components/StrataProjects.vue";
import MapCanvas from "@/components/MapCanvas.vue";
import SoilSystemPlan from "@/components/SoilSystemPlan.vue";
import FoundationsPlan from "@/components/FoundationsPlan.vue";
import BreadcrumbBanner from "@/components/misc/BreadcrumbBanner.vue";
import observers from "../Helpers/observers";
import animations from "../Helpers/animations";
import NewsletterForm from "@/components/forms/NewsletterForm.vue";
import AppFooter from "@/components/Navigation/AppFooter.vue";
import ContactPanels from "@/components/ContactPanels.vue";
import "intersection-observer";
import Scrollama from "vue-scrollama";
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    AppHeader,
    Banner,
    ExploreMore,
    StrataFoundations,
    Partners,
    Testmonials,
    NewsletterForm,
    AppFooter,
    Scrollama,
    ContactPanels,
    StrataProjects,
    SoilSystemPlan,
    MapCanvas,
    FoundationsPlan,
    BreadcrumbBanner,
  },
  data() {
    return {
      scrollDirection: "",
      scrollProgress: "",
      scrollEle: "",
      scrollIndex: null,
      pin: false,
      isScrolling: false,
      indicator: 1,
      lastScrollTop: 0,
      wheelOpt: "",
      wheelEvent: "",
      event: "",
      eventStartY: "",
      eventLastY: "",
      touchDiff: "",
      waiting: false,
      waitTimeOut: "",
      pageSections: null,
      loading: false,
    };
  },
  props: [
    "width",
    "changeHeader",
    "countSectionsInPage",
    "setSectionId",
    "chooseSection",
    "forceInitialScroll",
    "changeForceScroll",
    "openModal",
    "closeModal",
    'secId',
    'getSectionType'
  ],
  watch: {
    secId: function() {
      // console.log(this.renderSections[this.secId-1].style.type)
      this.getSectionType(this.renderSections[this.secId-1].style.type)
    },
    $route: function() {
      this.loading = true;
      this.loadVPage({
        id: this.$route.meta.page_id,
      }).then(res => {
        this.pageSections = this.sortItems(this.getVPage.sections).filter(sec => sec.status != false);
        setTimeout(() => {
          this.loading = false;
        }, 800)
      })
    },  
    scrollDirection: function (newDir, oldDir) {
      // console.log('on scroll direction change ... ')
      if (this.waiting != true && this.forceInitialScroll != true) {
        this.checkScroll();
      }
    },
    scrollIndex: function (newIndex, oldIndex) {
      // console.log('on scroll index change ... ')
      if (
        (newIndex !== 0 || newIndex !== 4) &&
        this.width == "sm" &&
        this.forceInitialScroll != true
      ) {
        var x = window.scrollX;
        var y = window.scrollY;
        window.onscroll = function () {
          window.scrollTo(x, y);
        };
        setTimeout(() => {
          window.onscroll = function () {};
        }, 800);
      }
    },
  },  
  mounted() {
    this.loadVPage({
      id: this.$route.meta.page_id
    }).then(res => {
      this.pageSections = this.sortItems(this.getVPage.sections).filter(sec => sec.status != false);
      // console.log('asdasdsads',this.pageSections.filter(sec => sec.status != false))
      document.addEventListener("keydown", this.checkKey);
      this.countSectionsInPage();
      this.lastScrollTop = 0;
      let element = document.getElementById("scrollhandlerContainer");
      if ("onwheel" in document.createElement("div")) {
        this.wheelEvent = "wheel";
      } else {
        this.wheelEvent = "mousewheel";
      }
      let supportsPassive;
      try {
        window.addEventListener("test", null, Object.defineProperty({}, "passive", {
          get: function () {
              supportsPassive = true;
            },
          })
        );
      } catch (e) {}
      this.wheelOpt = supportsPassive ? { passive: false } : false;
      // element should be replaced with the actual target element on which you have applied scroll, use window in case of no target element.
      if (this.width != "sm") {
        window.addEventListener(
          this.wheelEvent,
          this.preventDefault,
          this.wheelOpt
        ); // modern desktop
        window.addEventListener("scroll", this.handleDirection, false);
        window.addEventListener("DOMMouseScroll", this.preventDefault, false); // older FF
        window.addEventListener(
          "touchstart",
          this.preventDefaultTouchStart,
          this.wheelOpt
        ); // mobile
        window.addEventListener("touchmove", this.preventDefault, this.wheelOpt); // mobile
        window.addEventListener(
          "touchend",
          this.preventDefaultTouchEnd,
          this.wheelOpt
        ); // mobile
      }
      
    })

    // call this to Enable
  },
  destroyed() {
    // console.log('are we here now !!! destroying...')
    document.removeEventListener("keydown", this.checkKey);
    window.removeEventListener("scroll", this.handleDirection, false);
    window.removeEventListener("DOMMouseScroll", this.preventDefault, false);
    window.removeEventListener(
      this.wheelEvent,
      this.preventDefault,
      this.wheelOpt
    );
    window.removeEventListener(
      "touchstart",
      this.preventDefaultTouchStart,
      this.wheelOpt
    );
    window.removeEventListener("touchmove", this.preventDefault, this.wheelOpt);
    window.removeEventListener(
      "touchend",
      this.preventDefaultTouchEnd,
      this.wheelOpt
    ); // mobile
  },
  methods: {
    ...mapActions(['getSections', 'loadVPage']),
    sortItems(items) {
        let sortedItems;
        sortedItems = items.sort((a, b) => {
            return parseInt(a.ord) - parseInt(b.ord);
        });
        return sortedItems;
    },
    progressHandler({ element, index, progress }) {
      // console.log('but are we here ? ... ')
      this.scrollIndex = index;
      this.scrollProgress = progress;
      this.scrollEle = element;
      let currentSection = document.getElementById(index);
      let faderChildren = currentSection.getElementsByClassName("fade-in");
      let slidingChildren = currentSection.getElementsByClassName("slide-in");
      let otherSections = document.getElementsByClassName("fixed-div");
      let scalingChildren =
        document.getElementsByClassName("scale-in-top-right");
      let diagonalFadeingChildren =
        document.getElementsByClassName("diagonal-fade-in");
      this.setSectionId(index + 1);
      if (currentSection && progress >= 0.0001 && progress <= 0.9999) {
        this.indicator = 0;
        currentSection.classList.add("appear");

        setTimeout(() => {
          for (let i = 0; i < faderChildren.length; i++) {
            faderChildren[i].classList.add("appear");
          }
          for (let i = 0; i < slidingChildren.length; i++) {
            slidingChildren[i].classList.add("appear");
          }
          for (let i = 0; i < scalingChildren.length; i++) {
            scalingChildren[i].classList.add("appear");
          }
          for (let i = 0; i < diagonalFadeingChildren.length; i++) {
            diagonalFadeingChildren[i].classList.add("appear");
          }
        }, 400);
        otherSections.forEach((el, elIndex) => {
          let faderChildren = el.getElementsByClassName("fade-in");
          let slidingChildren = el.getElementsByClassName("slide-in");
          let scalingChildren = el.getElementsByClassName("scale-in-top-right");
          let diagonalFadeingChildren =
            el.getElementsByClassName("diagonal-fade-in");
          if (index !== elIndex) {
            el.classList.remove("appear");
            setTimeout(() => {
              for (let i = 0; i < faderChildren.length; i++) {
                faderChildren[i].classList.remove("appear");
              }
              for (let i = 0; i < slidingChildren.length; i++) {
                slidingChildren[i].classList.remove("appear");
              }
              for (let i = 0; i < scalingChildren.length; i++) {
                scalingChildren[i].classList.remove("appear");
              }
              for (let i = 0; i < diagonalFadeingChildren.length; i++) {
                diagonalFadeingChildren[i].classList.remove("appear");
              }
            }, 1);
          }
        });
      } else if (currentSection) {
        if (progress < 0.01) {
          this.indicator = -1;
        } else if (progress > 0.99) {
          this.indicator = 1;
        }
        if (index - 1 == -1 && this.scrollDirection == "up") {
          this.indicator = 2;
        } else if (index + 1 != 6 && this.scrollDirection == "down") {
          this.indicator = 2;
        } else {
          this.indicator = 0;
          currentSection.classList.remove("appear");
        }
      }
    },
    snapSection() {
      if (this.scrollProgress == 0 || this.scrollProgress == 1) {
        return 0;
      }
      let snapElement;
      let sectionId = parseInt(
        this.getSectionIndex(this.scrollEle.getAttribute("id"))
      );
      if (this.indicator == -1) {
        snapElement = document.getElementById(`section-${sectionId - 1}`);
        this.setSectionId(sectionId - 1);
      } else if (this.indicator == 0) {
        snapElement = document.getElementById(`section-${sectionId}`);
        this.setSectionId(sectionId);
      } else if (this.indicator == 1) {
        snapElement = document.getElementById(`section-${sectionId + 1}`);
        this.setSectionId(sectionId + 1);
      }
      if (snapElement) {
        snapElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        this.pin == false;
      }
    },
    getSectionIndex(id) {
      return parseInt(id.split("-")[1]);
    },
    checkKey(e) {
      if (e.keyCode == 40) {
        e.preventDefault();
        if (this.scrollIndex + 1 != 6) {
          this.indicator = 1;
          this.scrollDirection = "down";
          this.snapSection();
        }
      } else if (e.keyCode == 38) {
        e.preventDefault();
        if (this.scrollIndex - 1 != -1) {
          this.indicator = -1;
          this.scrollDirection = "up";
          this.snapSection();
        }
      }
    },
    checkScroll() {
      let element;
      // console.log('checking scroll direction ....')
      if (this.scrollDirection == "down" && this.scrollIndex != 4) {
        element = document.getElementById(`section-${this.scrollIndex + 1}`);
      } else if (this.scrollDirection == "up" && this.scrollIndex != 0) {
        element = document.getElementById(`section-${this.scrollIndex - 1}`);
      }
      this.waiting = true;
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
      setTimeout(() => {
        if (this.waiting == true) {
          this.waiting = false;
          this.scrollDirection = null;
        }
      }, 450);
    },
    handleDirection() {
      var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
      if (st > this.lastScrollTop) {
        this.scrollDirection = "down";
      } else {
        this.scrollDirection = "up";
      }
      this.lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    },
    preventDefault(e) {
      // e.preventDefault();
      if (this.waiting == false && this.forceInitialScroll != true) {
        if (e.deltaY && e.deltaY > 7) {
          this.scrollDirection = "down";
          this.checkScroll();
        } else if (e.deltaY && e.deltaY < -8) {
          this.scrollDirection = "up";
          this.checkScroll();
        } else {
        }
      }
    },
    preventDefaultTouchStart(e) {
      e.preventDefault();
      this.eventStartY = e.changedTouches[0].pageY;
    },
    preventDefaultTouchEnd(e) {
      e.preventDefault();
      this.eventLastY = e.changedTouches[0].pageY;
      this.touchDiff = this.eventStartY - this.eventLastY;
      if (this.waiting == false && this.forceInitialScroll != true) {
        if (this.touchDiff > 0) {
          this.scrollDirection = "down";
          this.checkScroll();
        } else if (this.touchDiff < 0) {
          this.scrollDirection = "up";
          this.checkScroll();
        }
      }
    },
    forceSwitchSection(index) {
      let faderChildren = document.getElementsByClassName("fade-in");
      let slidingChildren = document.getElementsByClassName("slide-in");
      let scalingChildren =
        document.getElementsByClassName("scale-in-top-right");
      let diagonalFadeingChildren =
        document.getElementsByClassName("diagonal-fade-in");
      let currentSection = document.getElementById(index);
      let otherSections = document.getElementsByClassName("fixed-div");
      currentSection.classList.add("appear");
      otherSections.forEach((el, elIndex) => {
        let faderChildren = el.getElementsByClassName("fade-in");
        let slidingChildren = el.getElementsByClassName("slide-in");
        let scalingChildren = el.getElementsByClassName("scale-in-top-right");
        let diagonalFadeingChildren =
          el.getElementsByClassName("diagonal-fade-in");
        if (index !== elIndex) {
          el.classList.remove("appear");
          
        }
      });
    },
  },
  computed: {
    ...mapGetters(['', 'getVPage']),
    renderSections() {
      return this.pageSections;
    }
  }
};
</script>
