<template>
  <div id="app">
    <transition name="preload">
      <Preloader v-if="loading || dataLoading" :calcLoading="calcLoading" />
    </transition>

    <div v-if="!loading">
      <AppHeader
        :secondary="secondary"
        :sticky="width != 'sm' ? sticky : true"
        :width="width"
        :secId="secId"
        :changeForceScroll="changeForceScroll"
      />
      <div>
        <vue-page-transition>
          <router-view
            :changeHeader="changeHeader"
            :width="width"
            :openModal="openModal"
            :closeModal="closeModal"
            :countSectionsInPage="countSectionsInPage"
            :chooseSection="chooseSection"
            :setSectionId="setSectionId"
            :forceInitialScroll="forceInitialScroll"
            :changeForceScroll="changeForceScroll"
            :myModalShow="myModalShow"
            :secId='secId'
            :getSectionType='getSectionType'
          />
        </vue-page-transition>
        <!-- <NewsletterForm  class="mt-5 "/> -->
        <!-- <AppFooter :width='width'/> -->
        <FloatingMenu 
          v-if="width != 'sm'" 
          :showHeader='showHeader'
          :width='width'
        />
        <div
          :class="[
            'mouse-scroll-container',
            secId > 1 ? 'disappear' : '',
          ]"
          v-if="contactRoutes == false"
          @click="chooseSection(secId + 1, true)"
        >
          <h6
            class="banner-text-white"
            id="mouse-text"
            v-if="width != 'sm'"
            :class="firstSectionInHome || lastSection"
          >
            Click OR Scroll Down
          </h6>
          <div
            class="mouse-scroll"
            @mouseover="mouseHovered"
            @mouseout="mouseHoveredOut"
            :class="[firstSectionInHome]"
          ></div>
          <div class="mouse-divider" :class="firstSectionInHome"></div>
        </div>
        <div class="scroll-btns" :class="startOrEndSection">
          <div class="w-100">
            <button
              class="shadow-eff w-100"
              type="button"
              :class="width == 'sm' ? 'btn-icon-scroll' : 'd-none'" 
              @click="chooseSection(secId + 1)"
            >
              <span class="px-2" v-if="width == 'sm' && contactRoutes == false"
                >Scroll Down</span
              >
              <font-awesome-icon
                v-if="width == 'sm' && contactRoutes == false"
                :icon="['fas', 'chevron-down']"
                size="sm"
              />
              <font-awesome-icon
                v-else
                :icon="['fas', 'chevron-down']"
                size="sm"
              />
            </button>
          </div>
          <div class="w-100">
            <button
              class=" shadow-eff w-100"
              :class="width == 'sm' ? 'btn-icon-scroll' : 'd-none'" 
              type="button"
              @click="chooseSection(secId - 1)"
            >
              <span class="px-2" v-if="width == 'sm' && contactRoutes == false"
                >Scroll Up</span
              >
              <font-awesome-icon
                v-if="width == 'sm' && contactRoutes == false"
                :icon="['fas', 'chevron-up']"
                size="sm"
              />
              <font-awesome-icon
                v-else
                :icon="['fas', 'chevron-up']"
                size="sm"
              />
            </button>
          </div>
          <button
            class="btn-icon-pri shadow-eff d-none"
            type="button"
            v-if="width != 'sm'"
            @click="showHeader"
          >
            <font-awesome-icon  :icon="['fas', 'bars']" size="lg" />
          </button>
        </div>
        <!-- <SectionIndicator 
          v-if='width != "sm" && contactRoutes == false'
          :secId='secId' 
          :chooseSection='chooseSection' 
          :sectionsNo='sectionsNo' 
          :setSectionId='setSectionId'
        /> -->
      </div>
    </div>
    <div class="modal fade" id="projectModal">
      <div
        class="modal-dialog modal-xl"
        :class="gallery == true ? 'modal-dialog-centered' : ''"
      >
        <div class="modal-content">
          <div class="modal-header p-4">
            <h5 class="number-text ms-auto" v-if="dataLoaded && gallery">
              {{ openedModal.title.en }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="text-center pt-5" v-if="dataLoaded && !gallery">
            <h5 class="number-text">{{ openedModal.title.en }}</h5>
            <!-- <div class="project-description">
              Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Praesent
              Ac Tortor Venenatis, Malesuada Sapien Ut, Cursus Nunc. Donec
            </div> -->
          </div>
          <div class="modal-body" :class="gallery == true ? 'py-5 px-2' : ''">
            <div
              id="carouselExampleIndicators"
              class="carousel slide"
              data-bs-ride="carousel"
            >
              <div class="carousel-indicators">
                <button
                  v-for="(slide, slideInd) in openedModal.images"
                  :key="slideInd"
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  :data-bs-slide-to="slideInd"
                  class="active"
                  aria-current="true"
                  :aria-label="`Slide${slideInd}`"
                ></button>
              </div>
              <div class="carousel-inner fixed-height">
                <div
                  class="carousel-item"
                  :class="slideInd == 0 ? 'active' : ''"
                  v-for="(slide, slideInd) in openedModal.images"
                  :key="slideInd"
                >
                <!-- {{slide}} -->
                  <img  
                    v-if='slide.url'
                    :src="slide.url"
                    class="d-block slide-photo w-100"
                    :class="gallery == true ? 'slide-photo-lg' : ''"
                  />
                </div>
              </div>
              <button
                class="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button
                class="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
            <div class="modal-cards mt-4" v-if="gallery == false">
              <div class="row justify-content-center">
                <div
                  class="col-xl-3 col-12 my-2 justify-content-center d-flex"
                  v-for="(card, cardInd) in modalCards"
                  :key="cardInd"
                >
                  <div class="modal-card d-flex justify-content-between">
                    <div class="text-start">
                      <div class="banner-text">{{ card.label }}</div>
                      <div class="modal-values" v-if='card.label == "Value"'>{{ numberWithCommas(card.value) }}</div>
                      <div class="modal-values" v-else>{{ card.value }}</div>
                    </div>
                    <div class="my-auto">
                      <div class="menu-text-icon" v-if="!card.icon.icon">
                        {{ card.icon }}
                      </div>
                      <font-awesome-icon
                        v-else
                        :icon="[card.icon.type, card.icon.icon]"
                        size="2x"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="project-description px-5 my-5"
              v-if="gallery == false && dataLoaded"
              v-html='openedModal.description.en'
            >
            </div>
          </div>
          <!-- <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from "@/components/Navigation/AppHeader.vue";
import AppFooter from "@/components/Navigation/AppFooter.vue";
import NewsletterForm from "@/components/forms/NewsletterForm.vue";
import Preloader from "@/components/misc/Preloader.vue";
import FloatingMenu from "@/components/Navigation/FloatingMenu.vue";
import SectionIndicator from "@/components/misc/SectionIndicator.vue";
import { mapActions, mapGetters } from "vuex";
// import observers from './Helpers/observers';
import helpers from './Helpers/helpers';

export default {
  components: {
    AppHeader,
    AppFooter,
    NewsletterForm,
    Preloader,
    FloatingMenu,
    SectionIndicator,
  },
  data() {
    return {
      placeHolder: require('./assets/images/logos/Group 1.png'),
      dataLoaded: false,
      modalCards: [
        {
          label: "Client Name",
          icon: { icon: "user", type: "far" },
          value: "",
        },
        { label: "Value", icon: "EGP", value: "" },
        {
          label: "Duration",
          icon: { icon: "clock", type: "far" },
          value: "1",
        },
      ],
      secondary: false,
      sticky: true,
      innerWidth: window.innerWidth,
      loading: true,
      lastScrollTop: 0,
      myModal: "",
      openedModal: {},
      sectionsNo: 0,
      secId: 0,
      forceInitialScroll: false,
      myModalShow: false,
      myModalEle: "",
      gallery: false,
      secType: ''
    };
  },
  watch: {
    $route: function (newRoute, oldRoute) {
      if (newRoute !== oldRoute) {
        // this.countSectionsInPage();
        this.forceInitialScroll = true;
        this.controlScroll();
        this.awaitFirstSection();
        this.showHeader();
      }
    },
  },
  mixins: [helpers],
  created() {
    this.loadSettings()
      .then((res) => {
        if (res.status == 200) {
          // console.log("Done");
        }
      })
      .catch((err) => {
        console.error(err);
      });
    this.controlScroll();
    this.$loadScript("../../js/bootstrap.bundle.js").then((res) => {
      this.myModal = new bootstrap.Modal(
        document.getElementById("projectModal")
      );
      this.myModalEle = document.getElementById("projectModal");
      this.myModalEle.addEventListener("shown.bs.modal", this.handleModalShown);
      this.myModalEle.addEventListener(
        "hidden.bs.modal",
        this.handleModalhidden
      );
    });
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("scroll", this.handleScrollDirection, false);
  },
  methods: {
    ...mapActions(["loadVProject", "loadVSolution", "loadSettings"]),
    showHeader() {
      const header = document.getElementById("headerEle");
      header.style.opacity = 1;
      if (header.style.opacity == "1" && !this.contactRoutes && this.width != 'sm') {
        setTimeout(() => {
          header.style.opacity = 0;
        }, 2000);
      }
    },
    changeForceScroll(force) {
      this.forceInitialScroll = force;
      setTimeout(() => {
        this.forceInitialScroll = false;
      }, 400);
    },
    handleResize() {
      this.innerWidth = window.innerWidth;
    },
    handleModalhidden() {
      this.myModalShow = false;
    },
    handleModalShown() {
      this.myModalShow = true;
    },
    calcLoading(isLoaded) {
      this.loading = !isLoaded;
    },
    changeHeader() {
      this.secondary = !this.secondary;
    },
    handleScrollDirection() {
      var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
      if (st > this.lastScrollTop && !this.contactRoutes && this.width != 'sm') {
        this.sticky = false;
      } else {
        this.sticky = true;
        setTimeout(() => {
          if (this.sticky == true && !this.contactRoutes &&  this.width != 'sm') {
            this.sticky = false;
          }
        }, 4000);
      }
      this.lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    },
    openModal(project, id) {
      if (project.length && !project.title) {
        this.loadVSolution({ id: id })
          .then((res) => {
            if (res.status == 200 && this.getVSolution != null) {
              this.openedModal = this.getVSolution;
              this.dataLoaded = true;
              this.gallery = true;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.loadVProject({ id: project.id })
          .then((res) => {
            if (res.status == 200 && this.getVProject != null) {
              this.gallery = false;
              this.openedModal = this.getVProject;
              this.modalCards[0].value = this.getVProject.client_name;
              this.modalCards[1].value = this.getVProject.cost;
              this.modalCards[2].value = this.getVProject.duration;
              this.dataLoaded = true;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      this.myModal.show();
    },
    closeModal() {
      this.openedModal = {};
      this.myModal.hide();
    },
    countSectionsInPage() {
      this.sectionsNo =
        document.getElementsByClassName("body-protect-view").length;
    },
    chooseSection(secId, mouseScroll) {
      if (this.width == "sm" && !this.firstSectionInHome && mouseScroll == true) {
        return;
      }      
      let ele = document.getElementById(`section-${secId - 1}`);
      ele.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      // this.setSectionId(secId);
    },
    setSectionId(secId) {
      this.secId = secId;
    },
    getSectionType(secType) {
      this.secType = secType;
    },
    mouseHovered() {
      let ele = document.getElementById("mouse-text");
      ele.style.opacity = 1;
    },
    mouseHoveredOut() {
      let ele = document.getElementById("mouse-text");
      ele.style.opacity = 0;
    },
    awaitFirstSection() {
      if (this.secId == 1) {
        this.forceInitialScroll = false;
        // window.scrollTo(0, 0)
      } else {
        setTimeout(() => {
          this.awaitFirstSection();
          if (this.controlledRoutes.includes(this.$route.name)) {
            window.scrollTo(0, 0);
          }
        }, 200);
      }
    },
    controlScroll() {
      if (this.controlledRoutes.includes(this.$route.name) && this.width != "sm") {
        document.querySelector("body").classList.add("scrollControl");
        // document.querySelector('body').setAttribute('background', 'red')
      } else {
        document.querySelector("body").classList.remove("scrollControl");
      }
    },
  },
  computed: {
    ...mapGetters(["getVProject", "getVSolution", "getSettings", 'controlledRoutes', 'getVSlider', 'dataLoading']),
    width() {
      if (this.innerWidth < 576) {
        return "sm";
      } else if (this.innerWidth < 992) {
        return "md";
        // 768
        // }else if( this.innerWidth < 992 ){
        //   return 'lg'
        // }else if( this.innerWidth < 1200 ) {
        //   return 'xl'
      } else {
        return "lg";
      }
    },
    firstSectionInHome() {
      return this.$route.name == "Home" && this.secId == 1 ? "first" : "";
    },
    lastSection() {
      return this.secId === this.sectionsNo ? "first" : "";
    },
    startOrEndSection() {
      if (this.$route.name == "Home") {
        return "appear";
      } else {
        return this.secId !== this.sectionsNo ? "appear" : "";
      }
    },
    contactRoutes() {
      return this.$route.name == "Hiring" || this.$route.name == "ContactUs";
    },
  },
};
</script>


<style lang="scss">
@import "@/assets/styles/bootstrap";
@import "@/assets/styles/all.scss";
@import "@/assets/styles/swiper-bundle";

.preload-enter-active {
  transition: all 0.3s ease;
}
.preload-leave-active {
  transition: all 1s ease;
}
.preload-leave-to {
  opacity: 0;
}
</style>
