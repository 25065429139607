


export default {

  data() {
    return {

    }
  },
  methods: {
    sortitems(items) {
      let sortedItems;
      sortedItems = items.sort((a, b) => {
        return parseInt(a.ord) - parseInt(b.ord);
      });
      return sortedItems;
    },    
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  },
  computed: {

  }
}