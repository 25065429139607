  <template>
  <div class="row pb-5 slide-in" v-if="getTestimonials && getTestimonials.length > 1" >
    <div
      class="explore-header col-12 text-center mt-5"
      :class="width == 'sm' ? 'align-self-end' : 'align-self-center'"
    >
      {{section.title}}
    </div>
    <div class="">
      <div class="tesmonails d-none d-lg-block" id="testmonials-block">
        <!-- {{testmonialItems}} -->
        <div class="relative-sec">
          <!-- <TestmonialsSlider :items='testmonialItems'/> -->

          <div class="path-bg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 2091.391 399.393"
            >
              <path
                id="Path"
                class="testmonials-path"
                data-name="Path 2678"
                d="M1997.713,4709.232s318.032-292.119,567.746-310.965,229.69,256.782,431.11,235.58,188.464-320.388,374.571-320.388,191.408,313.321,369.86,320.388,343.946-292.119,343.946-292.119"
                transform="translate(-1995.594 -4311.958)"
                fill="none"
                stroke="#343f53"
                stroke-linecap="round"
                stroke-width="3"
                stroke-dasharray="15 16"
                opacity=".2"
              />
            </svg>
          </div>
          <Swiper
            class="swiper d-none d-lg-block"
            ref="testSwiper"
            :options="swiperOptions"
          >
            <SwiperSlide
              v-for="(card, index) in getTestimonials"
              :key="index"
              class="col-3 col-lg-4 col-xl-3 d-flex justify-content-center"
            >
            <!-- {{activeId}}
            {{index}}
            {{card.id}} -->
              <div
                :class="
                  activeId == index
                    ? 'test-card-rel change'
                    : 'test-card-rel'
                "
              >
                <div
                  class="test-card-avatar"
                  :class="activeId == index ? '' : `test-p${activeId}`"
                >
                  <img :src="card.image_url" />
                </div>
                <div
                  :class="
                    activeId == index
                      ? 'test-card-info change'
                      : 'test-card-info'
                  "
                >
                  <div class="my-2 banner-text" v-if='card.title != "null"'>{{ card.title }}</div>
                  <div class="my-2 modal-values" v-if='card.description != "null"'>{{ card.description }}</div>
                  <div class="my-2 plan-desc" v-if='card.info != "null"'>{{ card.info }}</div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <TestmonialsSlider
        v-if="dataLoaded"
        :items="testmonialItems"
        :ids="otherIds"
        class="d-block d-lg-none"
      />
    </div>
  </div>
</template>
test-p1 
test-p2
test-p4
test-p3

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import TestmonialsSlider from "@/components/misc/TestmonialsSlider.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    Swiper,
    SwiperSlide,
    TestmonialsSlider,
  },
  data() {
    return {
      testmonialItems: null,
      dataLoaded: false,
      swiperOptions: {
        speed: 1000,
        autoplay: false,
        slidesPerView: "auto",
        spaceBetween: 40,
        grabCursor: true,
        loop: true,
        centeredSlides: true,
        slideToClickedSlide: true,
        slideNextClass: "test-p1",
        slidePrevClass: "test-p2",
      },
      activeImg: 0,
      otherIds: [],
      activeId: 0,
      swipeDirection: null,
      setPathRev: "",
      setPathRev: "",
      swiper: ''
    };
  },
  watch: {
    getTestimonials: function() {
      if(this.getTestimonials && this.getTestimonials.length > 1) {
        // this.activeId = this.testmonialItems[0].i      
        console.log(this.$refs.testSwiper)
        var interval = setInterval(() => {
            if (this.$refs.testSwiper && this.$refs.testSwiper.$swiper) {
              this.swiper = this.$refs.testSwiper.$swiper 
              console.log(this.swiper)
              this.swiper.on("slideChange", (e) => {
              this.swipeDirection = e.swipeDirection;
                // let path = document.getElementById("Path");
                // path.classList.remove('testmonials-path-rev')
                // path.classList.remove('testmonials-path')
                // clearTimeout(timeout)
                // if(this.swipeDirection == 'prev') {
                  //   path.classList.add('testmonials-path')
                // }else if(this.swipeDirection == 'next') {
                  //   path.classList.add('testmonials-path-rev')
                // }
                // const timeout = setTimeout(() => {
                  //   path.classList.remove('testmonials-path-rev')
                //   path.classList.remove('testmonials-path')
                // }, 1100);
                this.activeId = e.realIndex;
                console.log(this.activeId)
              });
              setInterval(() => {
                this.swiper.slideNext(2000);
              }, 3500);
              clearInterval(interval)
            }else {
            }
          }, 200)
      }
    }
  },
  mounted() {
    this.loadTestimonials()
      .then((res) => {
        if (res.status == 200) {
          // this.swiper.on('slideChange', (e) => {
          //   // this.switchTest()
          // })
          this.testmonialItems = this.getTestimonials;
          for (let i = 0; i < this.testmonialItems.length; i++) {
            this.otherIds.push(i);
          }
          this.dataLoaded = true;
          console.log(this.$refs.testSwiper.$swiper)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  destroyed() {
    clearInterval(this.swiperInterval);
  },
  props: ["width", 'section'],
  methods: {
    ...mapActions(["loadTestimonials"]),
    switchTest(id) {
      let index = this.getItemIndex(id);
      this.otherIds.splice(index, 1, this.activeImg);
      this.activeImg = id;
    },
    getItemIndex(id) {
      let item = this.otherIds.find((el) => el == id);
      return this.otherIds.indexOf(item);
    },
    // handleDragStart(e) {
    //   console.log(e);
    // },
    // handleDragEnd(e) {
    //   console.log(e);
    // },
  },
  computed: {
    ...mapGetters(["getTestimonials"]),
  },
};
</script>
      
