import Axios from 'axios';

export default {
    state: {
        sliders: null,
        viweSlider: [],
    },

    getters: {
        getSliders(state) {
            return state.sliders;
        },
        getVSlider(state) {
            return state.viweSlider;
        }
    },

    mutations: {
        setSliders(state, SlidersPayload) {
            state.sliders = SlidersPayload;
        },
        setVSlider(state, vTestiPayload) {
            state.viweSlider = vTestiPayload;
        }
    },
    actions: {
        loadSliders({ commit }) {
            return new Promise((resolve, reject) => {
                Axios.post(`/v1/website/sliders`)
                    .then(res => {
                        if (res.data.results != null) {
                            commit('setSliders', res.data.results);
                            resolve(res);
                        } else {
                            reject(res);
                        }
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        loadVSlider({ commit }, payload) {
            return new Promise((resolve, reject) => {
                Axios.post(`/v1/website/sliders/show/${payload.id}`).then(res => {
                    if (res.data.results != null) {
                        commit("setVSlider", res.data.results);
                        resolve(res);
                    } else {
                        reject(res);
                    }
                }).catch(err => {
                    reject(err);
                });
            });
        }
    }
};
