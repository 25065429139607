<template>
  <div class="row justify-content-center w-100 pt-5">
    <img
      src="../../assets/images/nodata.svg"
      alt="No Data"
      style="width: 358px; margin-bottom: 50px"
    />
    <h5 class="text-center">No {{ dataName }} Found</h5>
  </div>
</template>
<script>
export default {
  props: ["dataName"],
};
</script>