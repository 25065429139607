import Axios from "axios";

export default {
    state: {
        pages: null,
        viewPage: null,
        dataLoading: false,
    },
    mutations: {
        setpages(state, pagesPayload) {
            state.pages = pagesPayload;
        },
        setVPage(state, vPagePayload) {
            state.viewPage = vPagePayload;
        },
        setDataLoading(state, payload) {
            console.log('state.dataLoading', payload)
            state.dataLoading = payload;
        }
    },
    getters: {
        getpages(state) {
            return state.pages;
        },
        getVPage(state) {
            return state.viewPage;
        },
        dataLoading(state) {
            return state.dataLoading
        },
    },
    actions: {
        loadpages({ commit }) {
            commit('setDataLoading', true)
            return new Promise((resolve, reject) => {
                Axios.post("/v1/website/pages").then(res => {
                    if (res.data.results != null) {
                        commit("setpages", res.data.results);
                        commit('setDataLoading', false)
                        resolve(res);
                    } else {
                        commit('setDataLoading', false)
                        reject(res);
                    }
                }).catch(err => {
                    commit('setDataLoading', false)
                    reject(err);
                });
            });
        },
        loadVPage({ commit }, payload) {
            return new Promise((resolve, reject) => {
                Axios.post(`/v1/website/pages/show/${payload.id}`).then(res => {
                    if (res.data.results != null) {
                        commit("setVPage", res.data.results);
                        resolve(res);
                    } else {
                        reject(res);
                    }
                }).catch(err => {
                    reject(err);
                });
            });
        }
    }
};