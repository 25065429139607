import Axios from "axios";

export default {
    state: {
        jobCategories: null,
        viewJobCategory: null
    },
    mutations: {
        setJobCategories(state, jobCategoriesPayload) {
            state.jobCategories = jobCategoriesPayload;
        },
        setVJobCategory(state, vJobCategoryPayload) {
            state.viewJobCategory = vJobCategoryPayload;
        }
    },
    getters: {
        getJobCategories(state) {
            return state.jobCategories;
        },
        getVJobCategory(state) {
            return state.viewJobCategory;
        }
    },
    actions: {
        loadJobCategories({ commit }) {
            return new Promise((resolve, reject) => {
                Axios.post("/v1/website/jobCategories").then(res => {
                    if (res.data.results != null) {
                        commit("setJobCategories", res.data.results);
                        resolve(res);
                    } else {
                        reject(res);
                    }
                }).catch(err => {
                    reject(err);
                });
            });
        },
        loadVJobCategory({ commit }, payload) {
            return new Promise((resolve, reject) => {
                Axios.post(`/v1/website/jobCategories/show/${payload.id}`).then(res => {
                    if (res.data.results != null) {
                        commit("setVJobCategory", res.data.results);
                        resolve(res);
                    } else {
                        reject(res);
                    }
                }).catch(err => {
                    reject(err);
                });
            });
        }
    }
};