import Axios from 'axios';

export default {
    state: {
        testimonials: null,
        viweTestimonial: null,
    },

    getters: {
        getTestimonials(state) {
            return state.testimonials;
        },
        getVTestimonial(state) {
            return state.viweTestimonial;
        }
    },

    mutations: {
        setTestimonials(state, testimonialsPayload) {
            state.testimonials = testimonialsPayload;
        },
        setVTestimonial(state, vTestiPayload) {
            state.viweTestimonial = vTestiPayload;
        }
    },
    actions: {
        loadTestimonials({ commit }) {
            return new Promise((resolve, reject) => {
                Axios.post(`/v1/website/testimonials`)
                    .then(res => {
                        if (res.data.results != null) {
                            commit('setTestimonials', res.data.results);
                            resolve(res);
                        } else {
                            reject(res);
                        }
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        loadVTestimonial({ commit }, payload) {
            return new Promise((resolve, reject) => {
                Axios.post(`/v1/website/testimonials/show/${payload.id}`).then(res => {
                    if (res.data.results != null) {
                        commit("setVTestimonial", res.data.results);
                        resolve(res);
                    } else {
                        reject(res);
                    }
                }).catch(err => {
                    reject(err);
                });
            });
        }
    }
};
