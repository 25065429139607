<template>
  <footer class="app-footer" id="appFooter">
    <div class="">
      <div class="row mx-9">
        <div class="col-12">
          <div class="row mb-4 justify-content-start">
            <!-- <div
              class="col-3 me-md-4 align-self-center"
              @click="goHome({ name: 'Home' })"
            > -->
          <!-- {{links}} -->
          <!-- {{controlledRoutes}} -->
              <!-- <img
                class="pointer"
                :src="
                  appLogo
                    ? appLogo
                    : require('../../assets/images/logos/Group 16297.png')
                "
                width="130"
              /> -->
              <!-- <img
                class="pointer"
                :src="
                   require('../../assets/images/logos/Group 16297.png')
                "
                width="130"
              />
            </div> -->
            <!-- <div class="col d-none d-md-flex">
              <div class="row footer-socials justify-content-center">
                <div class="col-12 px-md-3 d-flex">
                  <div
                    class="mx-0 mx-md-2"
                    v-for="(social, socialInd) in socialsItems"
                    :key="socialInd"
                    @click="openLink(social)"
                  >
                    <button class="btn-icon-trans" type="button">
                      <font-awesome-icon
                        :icon="['fab', social.icon]"
                        class=""
                        size="lg"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div> -->
            <div class="col">
              <div class="to-top">
                <button
                  class="btn-icon-pri shadow-eff"
                  type="button"
                  @click="goTop"
                >
                  <font-awesome-icon icon="chevron-up" />
                </button>
              </div>
            </div>
          </div>

          <div class="row justify-content-start ">
            <div class="col-xl-3 col-12 me-md-4 ms-1">
              <!-- <div class="footer-contacts my-1 my-md-2" >
                <span class="me-1">
                  <font-awesome-icon :icon="['fa', 'phone']" />
                </span>
                <a :href="`tel:${phone}`" class="footer-links-text">
                  {{ phone ? phone : "212 - 338 111 11" }}
                </a>
              </div>
              <div class="footer-contacts  my-1 my-md-2" >
                <span class="me-1">
                  <font-awesome-icon :icon="['fa', 'envelope']" />
                </span>
                <a :href="`mailto:${email}`" class="footer-links-text">
                  {{ email ? email : "info@strata-eg.com" }}
                </a>
              </div> -->
              <div
                class="d-flex ms-md-2 mb-4 mt-2 align-self-center"
                @click="goHome({ name: 'Home' })"
              >
                <img
                  class="pointer"
                  :src="
                    require('../../assets/images/logos/Group 16297.png')
                  "
                  width="120"
                />
              </div>
              <div class="col-12 d-flex">
                <div
                  class="mx-0 mb-2"
                  v-for="(social, socialInd) in socialsItems"
                  :key="socialInd"
                  @click="openLink(social)"
                >
                  <button class="btn-icon-trans" type="button">
                    <!-- {{social}} -->
                    <font-awesome-icon
                      :icon="['fab', social.icon]"
                      class=""
                      size="md"
                    />
                  </button>
                </div>
              </div>
              <div class="footer-contacts my-1 my-md-2 d-lg-none d-block">
                <span class="me-1">
                  <font-awesome-icon :icon="['fa', 'map-marker-alt']" />
                </span>
                <a href="https://goo.gl/maps/UuEN7Kb25inPPMqJ7" class="footer-links-text">
                  {{ address ? address : "8 El-Mansoura Road, EL Haram, Giza" }}
                </a>
              </div>
              <div class="footer-contacts my-1 my-md-2 d-lg-none d-block" >
                <span class="me-1">
                  <font-awesome-icon :icon="['fa', 'phone']" />
                </span>
                <a :href="`tel:${phone}`" class="footer-links-text">
                  {{ phone ? phone : "212 - 338 111 11" }}
                </a>
              </div>
              <div class="footer-contacts my-1 my-md-2 d-lg-none d-block" >
                <span class="me-1">
                  <font-awesome-icon :icon="['fa', 'envelope']" />
                </span>
                <a :href="`mailto:${email}`" class="footer-links-text">
                  {{ email ? email : "info@strata-eg.com" }}
                </a>
              </div>
            </div>
            <div class="col-xl-4 col-12 me-md-5 d-none d-lg-block">
              <div class="footer-contacts d-flex my-1 my-md-2">
                <span class="me-1">
                  <font-awesome-icon :icon="['fa', 'map-marker-alt']" />
                </span>
                <a href="https://goo.gl/maps/UuEN7Kb25inPPMqJ7" class="footer-links-text">
                  {{address ? address : "8 El-Mansoura Road, EL Haram, Giza"}}
                </a>
              </div>
              <div class="footer-contacts my-1 my-md-2" >
                <span class="me-1">
                  <font-awesome-icon :icon="['fa', 'phone']" />
                </span>
                <a :href="`tel:${phone}`" class="footer-links-text">
                  {{ phone ? phone : "212 - 338 111 11" }}
                </a>
              </div>
              <div class="footer-contacts my-1 my-md-2" >
                <span class="me-1">
                  <font-awesome-icon :icon="['fa', 'envelope']" />
                </span>
                <a :href="`mailto:${email}`" class="footer-links-text">
                  {{ email ? email : "info@strata-eg.com" }}
                </a>
              </div>
            </div>
            
            <div class="col-xl-4 col-12 mt-1 footer-links footer-links-text">
              <div
                v-for="(link, linkInd) in linkItems"
                :key="linkInd"
                class="text-left pointer my-1"
                @click="goPage(link)"
              >
                {{ link.label }}
              </div>
            </div>
          </div>

          <div
            class="d-flex d-md-none row footer-socials justify-content-center"
          >
            <div class="col-12 px-md-4 d-flex pb-5">
              <div
                class="mx-0 mx-md-2"
                v-for="(social, socialInd) in socialsItems"
                :key="socialInd"
                @click="openLink(social)"
              >
                <button class="btn-icon-trans" type="button">
                  <!-- {{social}} -->
                  <font-awesome-icon
                    :icon="['fab', social.icon]"
                    class=""
                    size="lg"
                  />
                </button>
              </div>
            </div>
          </div>
          <div class="to-top">
            <button
              class="btn-icon-pri shadow-eff"
              type="button"
              @click="goTop"
            >
              <font-awesome-icon icon="chevron-up" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      linkItems: [
        // { name: "Home", label: "Home" },
        // { name: "Foundations", label: "Foundations" },
        // { name: "SoilSystems", label: "Soil Systems" },
        { name: "ContactUs", label: "Contact us" },
        { name: "Hiring", label: "We Are Hiring" },
        // {name: '', label: 'Latest Updates'},
      ],
      links: [],
      socialLinks: [],
      socialsItems: [
        {
          name: "linkedin",
          icon: "linkedin-in",
          link: "https://www.linkedin.com/company/strataegypt/",
        },
        {
          name: "facebook",
          icon: "facebook-f",
          link: "https://www.facebook.com/strataegypt/",
        },
        {
          name: "instagram",
          icon: "instagram",
          link: "https://www.instagram.com/strataegypt/",
        },
        {
          name: "twitter",
          icon: "twitter",
          link: "https://twitter.com/StrataEgypt",
        },
        {
          name: "youtube",
          icon: "youtube",
          link: "https://www.youtube.com/channel/UCnHmt87QuSOxV80uRgEQbTw",
        },
        // {name: 'youtube', icon: 'youtube'},
      ],
      email: "",
      phone: "",
      address: "",
      appLogo: "",
    };
  },
  props: ["changeForceScroll"],
  methods: {
    goTop() {
      this.changeForceScroll(true);
      window.scrollTo(0, 0);
    },
    goHome(link) {
      if (this.$route.name == link.name) {
        this.goTop();
      } else {
        this.$router.push({
          name: link.name,
        });
      }
    },
    goPage(link) {
      if (this.$route.name == link.name) {
        this.goTop();
      } else if (link.name) {
        this.$router.push({
          name: link.name,
        });
      }
    },
    openLink(social) {
      window.open(social.link);
      // console.log(social.lonk);
    },
  },
  computed: {
    ...mapGetters(["getSettings", 'controlledRoutes']),
  },
  mounted() {
    this.controlledRoutes.forEach(el => {
      this.links.push({name: el, label: el});
    })
    this.linkItems = this.links.concat(this.linkItems)
    this.socialLinks = this.getSettings.social_media_links;
    this.socialsItems.forEach(el => {
      let compareLink =this.socialLinks.find(link => link.name.toLowerCase() == el.name); 
      if (compareLink && compareLink != -1) {
        el.link = compareLink.link;
        // console.log(compareLink)
      }
    })
    
    this.appLogo = this.getSettings.logo;
    this.email = this.getSettings.email;
    this.phone = this.getSettings.phone;
    this.address = this.getSettings.address;
  },
};
</script>