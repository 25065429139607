import Axios from "axios";

export default {
    state: {
        jobs: null,
        viewJob: null
    },
    mutations: {
        setJobs(state, jobsPayload) {
            state.jobs = jobsPayload;
        },
        setVJob(state, vJobPayload) {
            state.viewJob = vJobPayload;
        }
    },
    getters: {
        getJobs(state) {
            return state.jobs;
        },
        getVJob(state) {
            return state.viewJob;
        }
    },
    actions: {
        loadJobs({ commit }, payload) {
            return new Promise((resolve, reject) => {
                Axios.post("/v1/website/jobs", {
                    job_category: payload.job_category
                }).then(res => {
                    if (res.data.results != null) {
                        commit("setJobs", res.data.results);
                        resolve(res);
                    } else {
                        reject(res);
                    }
                }).catch(err => {
                    reject(err);
                });
            });
        },
        loadVJob({ commit }, payload) {
            return new Promise((resolve, reject) => {
                Axios.post(`/v1/website/jobs/show/${payload.id}`).then(res => {
                    if (res.data.results != null) {
                        commit("setVJob", res.data.results);
                        resolve(res);
                    } else {
                        reject(res);
                    }
                }).catch(err => {
                    reject(err);
                });
            });
        }
    }
};