<template>
  <div class="container-fluid relative-sec">
    <Swiper ref="mySwiperTop" class="swiper w-100" :options="swiperOptions">
      <SwiperSlide
        class="col-12 mx-2"
        v-for="(test, testInd) in items"
        :key="testInd"
      >
        <div class="test-card-rel">
          <div class="test-card-avatar">
            <img :src="test.image_url" />
          </div>
          <div class="my-2 banner-text" v-if='test.title != "null"'>{{ test.title }}</div>
          <div class="my-2 modal-values" v-if='test.description != "null"'>{{ test.description }}</div>
          <div class="my-2 plan-desc" v-if='test.info != "null"'>{{ test.info }}</div>
        </div>
      </SwiperSlide>
    </Swiper>
    <div class="d-block d-xl-none">
      <span class="m-3">
        <button
          class="btn-icon-pri shadow-eff"
          type="button"
          @click="swiper.slidePrev()"
        >
          <font-awesome-icon :icon="['fas', 'chevron-left']" />
        </button>
      </span>
      <span class="m-3">
        <button
          class="btn-icon-pri shadow-eff"
          type="button"
          @click="swiper.slideNext()"
        >
          <font-awesome-icon :icon="['fas', 'chevron-right']" />
        </button>
      </span>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOptions: {
        autoplay: false,
        slidesPerView: 1,
        spaceBetween: 20,
        grabCursor: true,
        loop: true,
        centeredSlides: true,
      },
      activeImg: 0,
    };
  },
  props: ["items", "ids"],
  mounted() {
    this.swiper.on("slideChange", (e) => {
      this.switchTest(e.activeIndex);
    });
  },
  methods: {
    switchTest(id) {
      let index = this.getItemIndex(id);
      this.ids.splice(index, 1, this.activeImg);
      this.activeImg = id;
    },
    getItemIndex(id) {
      let item = this.ids.find((el) => el == id);
      return this.ids.indexOf(item);
    },
  },
  computed: {
    swiper() {
      return this.$refs.mySwiperTop.$swiper;
    },
  },
};
</script>