<template>
  <div class="section-ind-container">
      <!-- {{currentSection}}
      {{secId}} -->
      <!-- {{sectionsNo}} -->
    <div class="section-ind-body " v-if='sectionsNo'>

      <div 
        class="pointer"
        @click='chooseSection(secId)'
        v-for='(secId, index) in sectionsNo'
        :key='secId'    
      >
        <div class="ind-divider" v-if='index != 0'></div>
        <div class="section-ind" :class="secId == currentSection ? 'active' : ''"  >
          <span 
            class="ind" 
            :class="secId == currentSection ? 'active' : ''"  
          ></span>
        </div>
      </div>
    </div>
  </div>  
</template>

<script>
export default {
  data() {
    return {
      currentSection: 1,
    }
  },
  watch: {
    secId: function(newID, oldID) {
      if(newID !== oldID) {
        this.currentSection = newID;
      }
    }
  },
  props: ['sectionsNo', 'setSectionId', 'chooseSection', 'secId'],
  methods: {
  },
  computed: {
  }
}
</script>