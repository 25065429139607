import Axios from "axios";

export default {
    actions: {
        subscribe({ commit }, payload) {
            console.log(payload);
            return new Promise((resolve, reject) => {
                Axios.post("/v1/website/subscribe/create", {
                    path: "asd",
                    email: payload,
                }).then(res => {
                    if (res.status == 200) {
                        resolve(res);
                    } else {
                        reject(res);
                    }
                }).catch(err => {
                    reject(err);
                });
            });

        }
    }
};