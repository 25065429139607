<template>
  <div class="row explore-more text-start">
    <div class="col-12 align-self-center">
      <div class="foundation-plan px-1 px-md-5">
        <div class="row">
          <div class="col-12 col-lg-6 d-block d-lg-none slide-in">
            <div class="horizontal-img-plan ms-md-5">
              <!-- <img
                class="img-fluid"
                src="../assets/images/sections/workers-putting-metal-structure-construction.jpg"
              /> -->
              <img
                class="img-fluid"
                :src="section.media_url"
              />

            </div>
          </div>
          <div class="col-12 col-lg-6 mt-4 fade-in" v-html='section.description'>
            <!-- <div class="w-75" v-for="(step, Ind) in steps" :key="Ind">
              <div class="plan-header my-2">{{ step.header }}.</div>
              <div class="banner-text my-2">Step {{ step.step }}</div>
              <div class="plan-desc my-3 my-md-4">{{ step.desc }}</div>
            </div> -->
            
          </div>
          <div
            class="col-12 col-lg-6 d-none d-lg-block align-self-center"
          >
            <div class="horizontal-img-plan ms-5 slide-in">
              <img
                class="img-fluid"
                :src="section.media_url"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      steps: [
        {
          header: "Planning",
          step: 1,
          desc: " Perfection Is Achieved, Not When There Is Nothing More To Add, But When There Is Nothing Left To Take Away.",
        },
        {
          header: "Design",
          step: 2,
          desc: " Design Is Not How It Looks Like And Feels Like. Design Is How It Works.",
        },
        { header: "Build", step: 3, desc: "When We Build, We Build Forever." },
      ],
    }
  },
  props:['section']
}
</script>