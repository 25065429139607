<template>
  <div
    class="container-fluid newsletter-section justify-content-center p-0"
    ref="newsletterSection"
  >
    <div class="row p-0 justify-content-center w-100">
      <div class="col-12 p-0 col-lg-11 text-center w-100">
        <h4 class="text-light" v-if="messageSent && !loading" v-html="messageSent"></h4>
        <h4 v-else class="form-header my-4 text-light">
          To Get Updated Subscribe to Our Newsletter
        </h4>
        <form @submit.prevent="submit" class="m-0 p-0">
          <div class="row justify-content-center w-100 m-0">
            <div class="col-xl-6 col-12">
              <div class="relative-sec">
                <input
                  required
                  type="email"
                  placeholder="Enter Your Email Here"
                  class="text-input-lg"
                  v-model="email"
                />
                <button
                  class="btn-pri btn-inform slide-header shadow-eff"
                  type="submit"
                >
                  SUBSCRIBE
                </button>
              </div>
            </div>
          </div>
        </form>
        <ProjectLoader v-if='loading' />
        
      </div>
    </div>
  </div>
</template>


<script>
import { mapActions } from "vuex";
import ProjectLoader from "@/components/misc/ProjectLoader.vue";
export default {
  components: {
    ProjectLoader
  },
  data() {
    return {
      email: "",
      messageSent: '',
      loading: false,
    };
  },
  methods: {
    ...mapActions(["subscribe"]),
    submit() {
      this.loading = true;
      this.subscribe(this.email)
        .then(res => {
          this.email = ''
          this.messageSent = "Thank you for subscribing";
          this.loading = false
        })
    },
  },
  mounted() {
    this.$refs.newsletterSection.style.backgroundImage = `url(${require("../../assets/images/sections/newsletter_background.jpg")})`;
  },
};
</script>