import Axios from 'axios';

export default {
    state: {
        solutions: null,
        viweSolution: null,
    },

    getters: {
        getSolutions(state) {
            return state.solutions;
        },
        getVSolution(state) {
            return state.viweSolution;
        }
    },

    mutations: {
        setSolutions(state, solutionsPayload) {
            state.solutions = solutionsPayload;
        },
        setVSolution(state, vSolPayload) {
            state.viweSolution = vSolPayload;
        }
    },
    actions: {
        loadSolutions({ commit }) {
            return new Promise((resolve, reject) => {
                Axios.post(`/v1/website/solutions`)
                    .then(res => {
                        if (res.data.results != null) {
                            commit('setSolutions', res.data.results);
                            resolve(res);
                        } else {
                            reject(res);
                        }
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        loadVSolution({ commit }, payload) {
            return new Promise((resolve, reject) => {
                Axios.post(`/v1/website/solutions/show/${payload.id}`).then(res => {
                    if (res.data.results != null) {
                        commit("setVSolution", res.data.results);
                        resolve(res);
                    } else {
                        reject(res);
                    }
                }).catch(err => {
                    reject(err);
                });
            });
        }
    }
};
