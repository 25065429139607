import Axios from "axios";

export default {
    state: {
        categories: [],
        viewCategory: null
    },
    mutations: {
        setCategories(state, categoriesPayload) {
            state.categories = categoriesPayload;
        },
        setVCategory(state, vCatePayload) {
            state.viewCategory = vCatePayload;
        }
    },
    getters: {
        getCategories(state) {
            return state.categories;
        },
        getVCategory(state) {
            return state.viewCategory;
        }
    },
    actions: {
        loadCategories({ commit }, payload) {
            return new Promise((resolve, reject) => {
                Axios.post("/v1/website/categories").then(res => {
                    if (res.data.results != null) {
                        commit("setCategories", res.data.results);
                        resolve(res);
                    } else {
                        reject(res);
                    }
                }).catch(err => {
                    reject(err);
                });
            });
        },
        loadVCategory({ commit }, payload) {
            return new Promise((resolve, reject) => {
                Axios.post(`/v1/website/categories/show/${payload.id}`).then(res => {
                    if (res.data.results != null) {
                        commit("setVCategory", res.data.results);
                        resolve(res);
                    } else {
                        reject(res);
                    }
                }).catch(err => {
                    reject(err);
                });
            });
        }
    }
};