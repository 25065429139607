import Axios from "axios";

export default {
    state: {
        banners: [],
        viweBanner: []
    },
    mutations: {
        setBanners(state, bannersPayload) {
            state.banners = bannersPayload;
        },
        setViweBanner(state, vBanPayload) {
            state.viweBanner = vBanPayload;
        }
    },
    actions: {
        loadBanners({ commit }) {
            return new Promise((resolve, reject) => {
                Axios.post("/v1/website/banners").then(res => {
                    if (res.data.results != null) {
                        commit("setBanners", res.data.results);
                        resolve(res);
                    } else {
                        reject(res);
                    }
                }).catch(err => {
                    reject(err);
                });
            });
        },
        loadVBanner({ commit }, payload) {
            return new Promise((resolve, reject) => {

                Axios.post(`/v1/website/banners/show/${payload.id}`).then((res) => {
                    if (res.data.results != null) {
                        commit("setViweBanner", res.data.results);
                        resolve(res);
                    } else {
                        reject(res);
                    }
                }).catch(err => {
                    reject(err);
                });
            });
        }
    },
    getters: {
        getBanners(state) {
            return state.banners;
        },
        getVBanners(state) {
            return state.banners;
        }
    }
};