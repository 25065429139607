<template>
  <div
    class="breadcrumb-banner"
    :style="{ backgroundImage: `url(.${overlay}),  url(${section && section.media_url || getBanner()})`, height: section.custom_fields ? section.custom_fields.cover_size+'vh' : '60vh' }"
  >
    <div class="row h-100">
      <div class="col-12 align-self-center text-center">
        <div class="slide-text my-3">
          {{ pageName }}
        </div>
        <!-- <div class=""> -->
          <!-- <nav aria-label="breadcrumb">
            <ol class="breadcrumb justify-content-center">
              <li
                class="breadcrumb-item pointer"
                @click="$router.push({ name: 'Home' })"
              >
                <a>Home</a>
              </li>
              <li class="breadcrumb-item pointer" aria-current="page">
                {{ pageName }}
              </li>
            </ol>
          </nav> -->
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      foundationBanner: require("../../assets/images/banners/٢٠٢٠٠٧٢٥_١٤٠٣٢١.jpg"),
      projectsBanner: require("../../assets/images/banners/٢٠٢٠٠٧٢٥_١٤٠٣٢١.jpg"),
      soilBanner: require("../../assets/images/banners/IMG-20210316-WA00222.jpg"),
      hiringBanner: require("../../assets/images/banners/17055.jpg"),
      contactUsBanner: require("../../assets/images/banners/unsplash.jpg"),
      overlay: require("../../assets/images/banners/٢٠٢٠٠٧٢٥_١٤٠٣٢١2.png"),
    };
  },
  props: ["pageName", 'section'],
  methods: {
    getBanner() {
      if (this.$route.name == "Foundations") {
        return this.foundationBanner;
      } else if (this.$route.name == "Soil Systems") {
        return this.soilBanner;
      } else if (this.$route.name == "Hiring") {
        return this.hiringBanner;
      } else if (this.$route.name == "ContactUs") {
        return this.contactUsBanner;
      } else if(this.$route.name == "Projects") {
        return this.projectsBanner;

      }
    },
  },
};
</script>