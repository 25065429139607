import Axios from "axios";

export default {
    state: {
        labels: null,
    },
    mutations: {
        setLabels(state, labelsPayload) {
            state.labels = labelsPayload;
        },
    },
    getters: {
        getLabels(state) {
            return state.labels;
        },
    },
    actions: {
        loadLabels({ commit }) {
            return new Promise((resolve, reject) => {
                Axios.post("/v1/website/labels").then(res => {
                    if (res.data.results != null) {
                        commit("setLabels", res.data.results);
                        resolve(res);
                    } else {
                        reject(res);
                    }
                }).catch(err => {
                    reject(err);
                });
            });
        }
    }
};