<template>
  <div class="">
    <div class="floating-menu-container">
      <div
        class="floating-links"
         v-if="width != 'sm'"
          @click="showHeader"
      >
        <div
          class="pointer floating-link-btn"
        >
          <div class="floating-link-icon">
            <font-awesome-icon  :icon="['fas', 'bars']" size="lg" />
          </div>
        </div>

      </div>
      <div
        class="floating-links"
        v-for="(link, linkInd) in menuLinks"
        :key="linkInd"
        @click="link.name != 'social' ? $router.push({ name: link.name }) : ''"
        @mouseenter="showLink(link, linkInd)"
        @mouseleave="hideLink(link, linkInd)"
      >
        <div
          class="pointer"
          :class="link.items ? 'floating-link-vertical' : 'floating-link'"
          :id="link.name"
        >
          <div class="floating-link-icon">
            <font-awesome-icon :icon="link.icon" size="lg" />
          </div>
          <div
            class="floating-link-name nav-text-sec"
            :id="link.icon"
            v-if="!link.items"
          >
            {{ link.label }}
          </div>
          <div class="floating-link-socials" :id="link.icon" v-else>
            <!-- <font-awesome-icon :icon='link.icon' size='lg'/> -->
            <div
              class="floating-link-social-icons py-3 bg-eff"
              v-for="social in link.items"
              :key="social.name"
              @click="openLink(social)"
            >
              <font-awesome-icon
                class=""
                :icon="['fab', social.icon]"
                size="lg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  data() {
    return {
      menuLinks: [
        { name: "ContactUs", label: "Contact us", icon: "envelope-open-text" },
        { name: "Hiring", label: "We Are Hiring", icon: "suitcase" },
        {
          name: "social",
          icon: "share-alt",
          items: [
            {
              name: "linkedin",
              icon: "linkedin-in",
              link: "https://www.linkedin.com/company/strataegypt/",
            },
            {
              name: "facebook",
              icon: "facebook-f",
              link: "https://www.facebook.com/strataegypt/",
            },
            {
              name: "instagram",
              icon: "instagram",
              link: "https://www.instagram.com/strataegypt/",
            },
            {
              name: "twitter",
              icon: "twitter",
              link: "https://twitter.com/StrataEgypt",
            },
            {
              name: "youtube",
              icon: "youtube",
              link: "https://www.youtube.com/channel/UCnHmt87QuSOxV80uRgEQbTw",
            },
            // {name: 'youtube', icon: 'youtube'},
          ],
        },
      ],
      socialLinks: [],
    };
  },
  props: ['showHeader', 'width'],
  mounted() {

    this.$store.dispatch('loadSettings', {})
      .then(res => {
        
        this.socialLinks = this.getSettings.social_media_links;
        this.menuLinks[2].items.forEach(el => {
          let compareLink =this.socialLinks.find(link => link.name.toLowerCase() == el.name); 
          if (compareLink && compareLink != -1) {
            el.link = compareLink.link;
            // console.log(compareLink)
          }
        })
      })
  },
  methods: {
    showLink(link, index) {
      let parent = document.getElementById(link.name);
      let element = document.getElementById(link.icon);
      let icons = document.getElementsByClassName("floating-link-social-icons");
      icons.forEach((el, elInd) => {
        setTimeout(() => {
          el.classList.add("open");
        }, 100 * (elInd + 1));
      });
      // console.log('show', parent);
      parent.classList.add("open");
      element.classList.add("open");
    },
    hideLink(link, index) {
      let parent = document.getElementById(link.name);
      let element = document.getElementById(link.icon);
      let icons = document.getElementsByClassName("floating-link-social-icons");
      icons.forEach((el, elInd) => {
        el.classList.remove("open");
        // setTimeout(() => {
        // }, 100 * (elInd + 1))
      });
      // console.log('show', element);
      parent.classList.remove("open");
      element.classList.remove("open");
    },
    openLink(social) {
      window.open(social.link, "_blank");
      // console.log(social.link);
    },
    
  },
  computed: {
    ...mapGetters(['getSettings'])
  }
};
</script>