import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from "./store/index";
import Axios from 'axios';
import LoadScript from 'vue-plugin-load-script';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faDotCircle, faClock, faUser } from '@fortawesome/free-regular-svg-icons';
import {
    faTimes,
    faBars,
    faCircle,
    faPlay,
    faLocationArrow,
    faMapMarkerAlt,
    faThLarge,
    faChevronUp,
    faChevronDown,
    faArrowRight,
    faArrowLeft,
    faArrowUp,
    faArrowDown,
    faChevronLeft,
    faChevronRight,
    faDollarSign,
    faShareAlt,
    faSuitcase,
    faEnvelopeOpenText,
    faPhone, faBriefcase, faChartLine, faFunnelDollar,
    faEnvelope, faShareAltSquare
} from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faYoutube, faLinkedinIn, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import SvgTransition from 'vue-svg-transition';
import VuePageTransition from 'vue-page-transition';
import Particles from "particles.vue";
import Popover from 'vue-js-popover';
import VueSocialSharing from 'vue-social-sharing';

import DynamicPage from '@/views/DynamicPage.vue';


library.add(faTimes, faMapMarkerAlt, faChevronUp, faChevronDown, faDotCircle, faArrowUp,
    faArrowDown, faArrowRight, faArrowLeft, faChevronLeft, faChevronRight, faDollarSign, faEnvelope, faShareAltSquare);
library.add(faShareAlt, faSuitcase, faBars, faEnvelopeOpenText, faEnvelope);
library.add(faFacebookF, faYoutube, faLinkedinIn, faTwitter, faInstagram);
library.add(faUser, faClock, faCircle, faPlay, faLocationArrow, faThLarge, faPhone, faBriefcase, faChartLine, faFunnelDollar, faMapMarkerAlt,);
Axios.defaults.baseURL = "https://test.optivius.com/strata/public_html";
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.config.productionTip = false;
Vue.use(Popover);
Vue.use(VueSocialSharing);
Vue.use(Particles);
Vue.use(VuePageTransition);
Vue.use(SvgTransition);
Vue.use(LoadScript);

new Vue({
    router,
    store,
    data() {
        return {

        }
    },
    created() {
        this.getDynamicRoutes()
    },
    methods: {
        sortItems(items) {
            let sortedItems;
            sortedItems = items.sort((a, b) => {
                return parseInt(a.ord) - parseInt(b.ord);
            });
            return sortedItems;
        },    
        getDynamicRoutes () {
          store.dispatch('loadpages', {})
            .then(res => {
                // console.log(res.data.results.data)
                let data = res.data.results.data;
                data = this.sortItems(data)
                // console.log('sorted pages', data)
                data.forEach(el => {
                    if(el.sections_count && el.sections_count > 0) {
                        this.createAndAppendRoute(el)
                    }
                })
            })
            // console.log(this.$router)
        },
        
        createAndAppendRoute(route) {
            let newRoute = {
                path: `/${route.path}`,
                component: DynamicPage,
                name: `${route.titles}`,
                props: {page_id: route.id},
                meta: {
                    page_id: route.id
                }
            }
            store.commit('addToDynamicRoutes', newRoute);
          this.$router.addRoute(newRoute)
        }
      },
    render: h => h(App)
}).$mount('#app');;

// var dpi_x = document.getElementById('dpi').offsetWidth;
// var width = screen.width / dpi_x;
// // var dpi_y = document.getElementById('dpi').offsetHeight
// const appEle = document.getElementById("app");
// window.addEventListener("load", ()=>{
//   const nWith =  parseInt(width)
//     if(nWith <= 14){
//         appEle.style.zoom = '80%'
//     }else{
//         appEle.style.zoom = '100%'
//     }
// })