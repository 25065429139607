var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"sticky-top",class:_vm.sticky == true ? 'sticky-show' : 'sticky-hidden',attrs:{"id":"headerEle"}},[_c('nav',{staticClass:"navbar navbar-expand-md app-header",class:_vm.$route.name != 'Home' || _vm.secondary == true
        ? 'app-header-sec'
        : 'app-header-home',attrs:{"id":"mainNav"}},[_c('div',{staticClass:"container-fluid"},[_c('a',{staticClass:"navbar-brand mx-4 pointer",on:{"click":function($event){return _vm.goPage('Home')}}},[_c('img',{attrs:{"src":_vm.$route.name != 'Home' || _vm.secondary == true
              ? require('../../assets/images/logos/Group 16297.png')
              : require('../../assets/images/logos/Group 1.png'),"width":"120"}})]),_c('button',{staticClass:"navbar-toggler",class:_vm.$route.name != 'Home' ? 'navbar-toggler-sec' : '',attrs:{"type":"button","data-bs-toggle":"collapse","data-bs-target":"#navbarSupportedContent","aria-controls":"navbarSupportedContent","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('font-awesome-icon',{attrs:{"slot":"initial","icon":"bars"},slot:"initial"})],1),_c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"navbarSupportedContent"}},[_c('ul',{staticClass:"navbar-nav me-auto mb-2 mb-lg-0"},[_vm._l((_vm.links),function(link,ind){return _c('span',{key:ind + 10},[(link.label !== 'Home' || _vm.$route.name !== 'Home')?_c('li',{staticClass:"nav-item mx-4",on:{"click":function($event){return _vm.goPage(link.name)}}},[_c('a',{staticClass:"nav-link pointer nav-text underline-eff",class:[
                  _vm.$route.name == link.name ? 'nav-text-active' : '',
                  _vm.$route.name != 'Home' || _vm.secondary == true
                    ? ''
                    : 'nav-text-sec' ],attrs:{"data-bs-toggle":"collapse","data-bs-target":"#navbarSupportedContent","aria-current":"page"}},[_vm._v(" "+_vm._s(link.label)+" ")])]):_vm._e()])}),_vm._l((_vm.menuItems),function(item,itemInd){return _c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.width == 'sm'),expression:"width == 'sm'"}],key:itemInd,staticClass:"mx-4",on:{"click":function($event){return _vm.goPage(item.name)}}},[(item.label)?_c('a',{staticClass:"nav-link nav-text pointer",class:_vm.$route.name != 'Home' || _vm.secondary == true
                  ? ''
                  : 'nav-text-sec',attrs:{"data-bs-toggle":"collapse","data-bs-target":"#navbarSupportedContent","aria-current":"page"}},[_vm._v(" "+_vm._s(item.label)+" ")]):_vm._e(),(item.items)?_c('div',{staticClass:"nav-link menu-text d-flex justify-content-between"},_vm._l((item.items),function(icon,iconInd){return _c('span',{key:iconInd,staticClass:"mx-3 pointer bg-eff",class:_vm.$route.name == 'Home' ? 'text-white' : ''},[_c('font-awesome-icon',{attrs:{"icon":['fab', icon.icon]}})],1)}),0):_vm._e()])})],2)]),(false)?_c('div',{staticClass:"dropdown float-right",attrs:{"id":"myDropdown"}},[_c('button',{class:_vm.$route.name != 'Home' || _vm.secondary == true
              ? 'btn-icon-trans'
              : 'btn-icon-home',attrs:{"type":"button","id":"dropdownMenuLink","data-bs-toggle":"dropdown","aria-expanded":"false"}},[_c('svg-transition',{attrs:{"size":_vm.size}},[_c('font-awesome-icon',{attrs:{"slot":"initial","icon":"bars"},slot:"initial"}),_c('font-awesome-icon',{attrs:{"icon":"times"}})],1)],1),_c('ul',{staticClass:"dropdown-menu dropdown-menu-end menu-list",attrs:{"aria-labelledby":"dropdownMenuLink"}},_vm._l((_vm.menuItems),function(item,itemInd){return _c('li',{key:itemInd + 10},[(item.label)?_c('a',{staticClass:"dropdown-item menu-item menu-text pointer"},[_vm._v(" "+_vm._s(item.label)+" ")]):_vm._e(),(item.items)?_c('div',{staticClass:"\n                dropdown-item\n                menu-item menu-text\n                d-flex\n                justify-content-between\n              "},_vm._l((item.items),function(icon,iconInd){return _c('span',{key:iconInd * 10,staticClass:"mx-3 pointer bg-eff"},[_c('font-awesome-icon',{attrs:{"icon":['fab', icon.icon],"size":"xs"}})],1)}),0):_vm._e()])}),0)]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }