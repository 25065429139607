import Axios from "axios";

export default {
    state: {
        projects: [],
        viewProject: null
    },
    mutations: {
        setProjects(state, projectsPayload) {
            state.projects = projectsPayload;
        },
        setVProject(state, vProPayload) {
            state.viewProject = vProPayload;
        }
    },
    getters: {
        getProjects(state) {
            return state.projects;
        },
        getVProject(state) {
            return state.viewProject;
        }
    },
    actions: {
        loadProjects({ commit }, payload) {
            return new Promise((resolve, reject) => {
                Axios.post("/v1/website/projects", {
                    category_id: payload.id,
                    status: payload.status,
                    limit: 100,
                }).then(res => {
                    if (res.data.results != null) {
                        commit("setProjects", res.data.results);
                        resolve(res);
                    } else {
                        reject(res);
                    }
                }).catch(err => {
                    reject(err);
                });
            });
        },
        loadVProject({ commit }, payload) {
            return new Promise((resolve, reject) => {
                Axios.post(`/v1/website/projects/show/${payload.id}`).then(res => {
                    if (res.data.results != null) {
                        commit("setVProject", res.data.results);
                        resolve(res);
                    } else {
                        reject(res);
                    }
                }).catch(err => {
                    reject(err);
                });
            });
        }
    }
};