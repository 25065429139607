<template>
  <div class="container-fluid">
    <div class="row explore-more text-start ">
      <div class="col-12 align-self-center py-4">
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="horizontal-img text-center slide-in my-2">
              <img class="img-fluid" :src='section.media_url'/>
            </div>
          </div>
          <div class="col-8 col-lg-6 fade-in" >
            <div class="divider mb-4"></div>
            <div class="horizontal-text">
              <div class="explore-header my-3">
                {{section.title}}
              </div>
              <div class="explore-subHeader my-4" v-html='section.description'>
                <!-- Strata Is A Member Of Sami Saad Holding, A Renowned Name
                In The Egyptian Market That Has Been Providing Nothing, But
                Excellence For More Than 60 Years. -->
              </div>
                <!-- <button class="btn-pri shadow-eff" type='button' >
                  Explore More
                </button> -->
            </div>
          </div>
          <div class="col-4 d-flex d-lg-none mb-5" v-if='width == "sm"'>
            <div class="number-stats-horizontal text-center">
              <div class="number-text-white pt-2 pb-1 "
                v-for='(stat, statInd) in statsItmes'
                :key='statInd'  
              >
                <!-- {{firstTime[statInd] == true ? statsItmes[statInd].number : countUp(statsItmes[statInd], statInd)}} -->
                <span :id="`C${statInd}`" v-if='scrollIndex == 1'>{{ countUp(statsItmes[statInd], statInd)}}</span>
                <span v-else>{{stat.number}}</span>
                <span class="mx-1" v-if='stat.percent'>%</span>
                <div class='stat-subText-white m-1'>
                  {{stat.text}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="number-stats row d-none d-lg-flex" v-if='width != "sm"'>
        <div 
          class="col number-text-white p-3 m-2 text-center"
          v-for='(stat, statInd) in statsItmes'
          :key='statInd'  
        >
        <!-- {{stat.number}} -->
        <!-- {{checkFirstTime(firstTime)}} -->
        <!-- {{sectionIndex+'fuck'}} -->
          <span :id="`C${statInd}`" v-if='scrollIndex == sectionIndex'>{{ countUp(statsItmes[statInd], statInd)}}</span>
          <span v-else>{{stat.number}}</span>
          <span class="mx-1" v-if='stat.percent'>%</span>
          <div class='stat-subText-white m-1'>
            {{stat.text}}
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import ICountUp from 'vue-countup-v2';
import observers from '../Helpers/observers';

export default { 
  components: {
    ICountUp
  },
  data() {
    return {
      statsItmes: [
        {number: 210, percent: true, text: 'Annual growth rate'},
        {number: 65, percent: true, text: 'Happy return clients'},
        {number: 24, percent: false, text: 'Hours executed support'},
        {number: 12, percent: false, text: 'diversified types of services'},
        {number: 0, percent: false, text: 'Incedints / Accidents'},
      ],
      delay: [13, 46, 88, 202, 42],
      firstTime: [false, false, false, false, false],
    }
  },
  props: ['scrollIndex', 'width', 'section', 'sectionIndex'],
  // mixins: [observers],
  watch: {
    index: function() {
      // let count = new CountUp()
      // this.countOnShow(documentbundleRenderer.renderToStream)
    }
  },
  mounted() {
    // console.log('mysection', this.section)
    this.statsItmes.forEach((el, index) => {
      if(this.section.custom_fields[`count${index+1}`]) {
        el.number = this.section.custom_fields[`count${index+1}`]
      }
    })
    // let counts = document.getElementsByClassName('counts');
    // counts.forEach(el => {
    //   let ele = new CountUp(el , delay, options);
    //   counters.push(ele);
    // })
    // this.counters.forEach(el => {
    //   el.start()
    // })
    // const obj = document.querySelectorAll(".counter");
    // obj.forEach(el => {
    //   animateValue(el, 100, 0, 5000);
    // })
  },
  methods: {
    // countOnShow(instance, CountUp) {
    //   this.counters = instance;
    //   this.countUps = CountUp;
    //   // instance.update(100)
    // },
    // countersStart() {
    //   console.log(this.counters,this.countUps, 'adsasd')
    //   this.counters.reset();
    //   // this.countUps.start();
    // }
    //   animateValue(obj, start, end, duration) {
    //     let startTimestamp = null;
    //     const step = (timestamp) => {
    //       if (!startTimestamp) startTimestamp = timestamp;
    //       const progress = Math.min((timestamp - startTimestamp) / duration, 1);
    //       obj.innerHTML = Math.floor(progress * (end - start) + start);
    //       if (progress < 1) {
    //         window.requestAnimationFrame(step);
    //       }
    //     };
    //     window.requestAnimationFrame(step);
    //   }
    countUp(countItem, index) {
      // console.log(this.firstTime)
      let start = 0;
      let step = countItem.number > start ? 1 : -1;

      if (start == countItem.number) {
        this.firstTime[index] = true;
        return countItem.number;
      }

      let interval = setInterval(() => {
        start += step;
        let ele = document.getElementById(`C${index}`);
        if (ele) {
          ele.textContent = start;
        }else {
          clearInterval(interval)
          return 0;
        }

        if( start == countItem.number ) {
          this.firstTime[index] = true;
          clearInterval(interval)
        }
      }, this.delay[index])
    },
    checkFirstTime(array) {
      array.forEach(el => {
        if(el == false) {
          return false;
        }else {
          return true
        }
      })
    }
  }
}
</script>