<template>
  <div class="" v-if='getVSlider && getVSlider.banners && getVSlider.banners.length > 0'>
    <!-- <Particles 
      id="tsparticles"
      :options='particlesOPtions'
    /> -->
    <!-- {{width}} -->
    <div class="">
      <div class="fixed-container">
        <Banner
          class="fixed-div switch"
          :scrollIndex="scrollIndex"
          :width="width"
          id="0"
        />
        <ExploreMore
          class="fixed-div switch"
          :scrollIndex="scrollIndex"
          :width="width"
          id="1"
        />
        <StrataFoundations
          class="fixed-div switch"
          :scrollIndex="scrollIndex"
          :width="width"
          id="2"
        />
        <!-- <Partners class="fixed-div switch" :scrollIndex='scrollIndex' :width='width' id='3'/> -->
        <Testmonials
          class="fixed-div switch"
          :scrollIndex="scrollIndex"
          :width="width"
          id="3"
        />
        <div class="fixed-div switch" id="4" :scrollIndex="scrollIndex">
          <div class="row explore-more p-0">
            <div class="col-12 align-self-end">
              <!-- <ContactPanels class="my-3"/> -->
              <NewsletterForm class="" />
              <Partners class="mt-0" :width="width" />
              <AppFooter
                :changeForceScroll="changeForceScroll"
                :width="width"
              />
            </div>
          </div>
        </div>
      </div>
      <Scrollama
        :offset="0.5"
        class="pb-news"
        @step-progress="progressHandler"
        id="scrollhandlerContainer"
      >
        <div class="body-protect-view" id="section-0"></div>
        <div class="body-protect-view" id="section-1"></div>
        <div class="body-protect-view" id="section-2"></div>
        <div class="body-protect-view" id="section-3"></div>
        <div class="body-protect-view" id="section-4"></div>
        <!-- <div class="body-protect-view" id='section-5'>
              </div> -->
      </Scrollama>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import AppHeader from "@/components/Navigation/AppHeader.vue";
import Banner from "@/components/Banner.vue";
import ExploreMore from "@/components/ExploreMore.vue";
import StrataFoundations from "@/components/StrataFoundations.vue";
import Testmonials from "@/components/Testmonials.vue";
import Partners from "@/components/Partners.vue";
import observers from "../Helpers/observers";
import animations from "../Helpers/animations";
import NewsletterForm from "@/components/forms/NewsletterForm.vue";
import AppFooter from "@/components/Navigation/AppFooter.vue";
import ContactPanels from "@/components/ContactPanels.vue";
import "intersection-observer";
import Scrollama from "vue-scrollama";
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    AppHeader,
    Banner,
    ExploreMore,
    StrataFoundations,
    Partners,
    Testmonials,
    NewsletterForm,
    AppFooter,
    Scrollama,
    ContactPanels,
  },
  data() {
    return {
      scrollDirection: "",
      scrollProgress: "",
      scrollEle: "",
      scrollIndex: null,
      pin: false,
      isScrolling: false,
      indicator: 1,
      lastScrollTop: 0,
      wheelOpt: "",
      wheelEvent: "",
      event: "",
      eventStartY: "",
      eventLastY: "",
      touchDiff: "",
      waiting: false,
      waitTimeOut: "",
    };
  },
  props: [
    "width",
    "changeHeader",
    "countSectionsInPage",
    "setSectionId",
    "chooseSection",
    "forceInitialScroll",
    "changeForceScroll",
  ],
  watch: {
    scrollDirection: function (newDir, oldDir) {
      if (this.waiting != true && this.forceInitialScroll != true) {
        this.checkScroll();
      }
    },
    scrollIndex: function (newIndex, oldIndex) {
      if (
        (newIndex !== 0 || newIndex !== 4) &&
        this.width == "sm" &&
        this.forceInitialScroll != true
      ) {
        var x = window.scrollX;
        var y = window.scrollY;
        window.onscroll = function () {
          window.scrollTo(x, y);
        };
        setTimeout(() => {
          window.onscroll = function () {};
        }, 800);
      }
    },
    getVSlider: function() {
      if(this.getVSlider && this.getVSlider.banners && this.getVSlider.banners.length > 0) {
        this.$forceUpdate();
      }
    }
  },
  mounted() {
    this.loadVSlider({
      id: 1,
    }).then((res) => {
      document.addEventListener("keydown", this.checkKey);
      this.countSectionsInPage();
      this.lastScrollTop = 0;
      let element = document.getElementById("scrollhandlerContainer");
      if ("onwheel" in document.createElement("div")) {
        this.wheelEvent = "wheel";
      } else {
        this.wheelEvent = "mousewheel";
      }
      let supportsPassive;
      try {
        window.addEventListener(
          "test",
          null,
          Object.defineProperty({}, "passive", {
            get: function () {
              supportsPassive = true;
            },
          })
        );
      } catch (e) {}
      this.wheelOpt = supportsPassive ? { passive: false } : false;
      // element should be replaced with the actual target element on which you have applied scroll, use window in case of no target element.
      if (this.width != "sm") {
        window.addEventListener(
          this.wheelEvent,
          this.preventDefault,
          this.wheelOpt
        ); // modern desktop
        window.addEventListener("scroll", this.handleDirection, false);
        window.addEventListener("DOMMouseScroll", this.preventDefault, false); // older FF
        window.addEventListener(
          "touchstart",
          this.preventDefaultTouchStart,
          this.wheelOpt
        ); // mobile
        window.addEventListener("touchmove", this.preventDefault, this.wheelOpt); // mobile
        window.addEventListener(
          "touchend",
          this.preventDefaultTouchEnd,
          this.wheelOpt
        ); // mobile
      }
    })

    // call this to Enable
  },
  destroyed() {
    document.removeEventListener("keydown", this.checkKey);
    window.removeEventListener("scroll", this.handleDirection, false);
    window.removeEventListener("DOMMouseScroll", this.preventDefault, false);
    window.removeEventListener(
      this.wheelEvent,
      this.preventDefault,
      this.wheelOpt
    );
    window.removeEventListener(
      "touchstart",
      this.preventDefaultTouchStart,
      this.wheelOpt
    );
    window.removeEventListener("touchmove", this.preventDefault, this.wheelOpt);
    window.removeEventListener(
      "touchend",
      this.preventDefaultTouchEnd,
      this.wheelOpt
    ); // mobile
  },
  methods: {
    ...mapActions(["loadBanners", "loadSliders", 'loadVSlider']),
    progressHandler({ element, index, progress }) {
      this.scrollIndex = index;
      this.scrollProgress = progress;
      this.scrollEle = element;
      let currentSection = document.getElementById(index);
      let faderChildren = currentSection.getElementsByClassName("fade-in");
      let slidingChildren = currentSection.getElementsByClassName("slide-in");
      let otherSections = document.getElementsByClassName("fixed-div");
      let scalingChildren =
        document.getElementsByClassName("scale-in-top-right");
      let diagonalFadeingChildren =
        document.getElementsByClassName("diagonal-fade-in");
      this.setSectionId(index + 1);
      if (currentSection && progress >= 0.0001 && progress <= 0.9999) {
        this.indicator = 0;
        currentSection.classList.add("appear");

        setTimeout(() => {
          for (let i = 0; i < faderChildren.length; i++) {
            faderChildren[i].classList.add("appear");
          }
          for (let i = 0; i < slidingChildren.length; i++) {
            slidingChildren[i].classList.add("appear");
          }
          for (let i = 0; i < scalingChildren.length; i++) {
            scalingChildren[i].classList.add("appear");
          }
          for (let i = 0; i < diagonalFadeingChildren.length; i++) {
            diagonalFadeingChildren[i].classList.add("appear");
          }
        }, 400);
        otherSections.forEach((el, elIndex) => {
          let faderChildren = el.getElementsByClassName("fade-in");
          let slidingChildren = el.getElementsByClassName("slide-in");
          let scalingChildren = el.getElementsByClassName("scale-in-top-right");
          let diagonalFadeingChildren =
            el.getElementsByClassName("diagonal-fade-in");
          if (index !== elIndex) {
            el.classList.remove("appear");
            setTimeout(() => {
              for (let i = 0; i < faderChildren.length; i++) {
                faderChildren[i].classList.remove("appear");
              }
              for (let i = 0; i < slidingChildren.length; i++) {
                slidingChildren[i].classList.remove("appear");
              }
              for (let i = 0; i < scalingChildren.length; i++) {
                scalingChildren[i].classList.remove("appear");
              }
              for (let i = 0; i < diagonalFadeingChildren.length; i++) {
                diagonalFadeingChildren[i].classList.remove("appear");
              }
            }, 1);
          }
        });
      } else if (currentSection) {
        if (progress < 0.01) {
          this.indicator = -1;
        } else if (progress > 0.99) {
          this.indicator = 1;
        }
        if (index - 1 == -1 && this.scrollDirection == "up") {
          this.indicator = 2;
        } else if (index + 1 != 6 && this.scrollDirection == "down") {
          this.indicator = 2;
        } else {
          this.indicator = 0;
          currentSection.classList.remove("appear");
        }
      }
    },
    snapSection() {
      if (this.scrollProgress == 0 || this.scrollProgress == 1) {
        return 0;
      }
      let snapElement;
      let sectionId = parseInt(
        this.getSectionIndex(this.scrollEle.getAttribute("id"))
      );
      if (this.indicator == -1) {
        snapElement = document.getElementById(`section-${sectionId - 1}`);
        this.setSectionId(sectionId - 1);
      } else if (this.indicator == 0) {
        snapElement = document.getElementById(`section-${sectionId}`);
        this.setSectionId(sectionId);
      } else if (this.indicator == 1) {
        snapElement = document.getElementById(`section-${sectionId + 1}`);
        this.setSectionId(sectionId + 1);
      }
      if (snapElement) {
        snapElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        this.pin == false;
      }
    },
    getSectionIndex(id) {
      return parseInt(id.split("-")[1]);
    },
    checkKey(e) {
      if (e.keyCode == 40) {
        e.preventDefault();
        if (this.scrollIndex + 1 != 6) {
          this.indicator = 1;
          this.scrollDirection = "down";
          this.snapSection();
        }
      } else if (e.keyCode == 38) {
        e.preventDefault();
        if (this.scrollIndex - 1 != -1) {
          this.indicator = -1;
          this.scrollDirection = "up";
          this.snapSection();
        }
      }
    },
    checkScroll() {
      let element;
      if (this.scrollDirection == "down" && this.scrollIndex != 4) {
        element = document.getElementById(`section-${this.scrollIndex + 1}`);
      } else if (this.scrollDirection == "up" && this.scrollIndex != 0) {
        element = document.getElementById(`section-${this.scrollIndex - 1}`);
      }
      this.waiting = true;
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
      setTimeout(() => {
        if (this.waiting == true) {
          this.waiting = false;
          this.scrollDirection = null;
        }
      }, 450);
    },
    handleDirection() {
      var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
      if (st > this.lastScrollTop) {
        this.scrollDirection = "down";
      } else {
        this.scrollDirection = "up";
      }
      this.lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    },
    preventDefault(e) {
      // e.preventDefault();
      if (this.waiting == false && this.forceInitialScroll != true) {
        if (e.deltaY && e.deltaY > 7) {
          this.scrollDirection = "down";
          this.checkScroll();
        } else if (e.deltaY && e.deltaY < -8) {
          this.scrollDirection = "up";
          this.checkScroll();
        } else {
        }
      }
    },
    preventDefaultTouchStart(e) {
      e.preventDefault();
      this.eventStartY = e.changedTouches[0].pageY;
    },
    preventDefaultTouchEnd(e) {
      e.preventDefault();
      this.eventLastY = e.changedTouches[0].pageY;
      this.touchDiff = this.eventStartY - this.eventLastY;
      if (this.waiting == false && this.forceInitialScroll != true) {
        if (this.touchDiff > 0) {
          this.scrollDirection = "down";
          this.checkScroll();
        } else if (this.touchDiff < 0) {
          this.scrollDirection = "up";
          this.checkScroll();
        }
      }
    },
    forceSwitchSection(index) {
      let faderChildren = document.getElementsByClassName("fade-in");
      let slidingChildren = document.getElementsByClassName("slide-in");
      let scalingChildren =
        document.getElementsByClassName("scale-in-top-right");
      let diagonalFadeingChildren =
        document.getElementsByClassName("diagonal-fade-in");
      let currentSection = document.getElementById(index);
      let otherSections = document.getElementsByClassName("fixed-div");
      currentSection.classList.add("appear");
      // setTimeout(() => {
      //   for (let i = 0; i < faderChildren.length ; i++) {
      //     faderChildren[i].classList.add('appear');
      //   }
      //   for (let i = 0; i < slidingChildren.length ; i++) {
      //     slidingChildren[i].classList.add('appear');
      //   }
      //   for (let i = 0; i < scalingChildren.length ; i++) {
      //     scalingChildren[i].classList.add('appear');
      //   }
      //   for (let i = 0; i < diagonalFadeingChildren.length ; i++) {
      //     diagonalFadeingChildren[i].classList.add('appear');
      //   }
      // }, 400);
      otherSections.forEach((el, elIndex) => {
        let faderChildren = el.getElementsByClassName("fade-in");
        let slidingChildren = el.getElementsByClassName("slide-in");
        let scalingChildren = el.getElementsByClassName("scale-in-top-right");
        let diagonalFadeingChildren =
          el.getElementsByClassName("diagonal-fade-in");
        if (index !== elIndex) {
          el.classList.remove("appear");
          // setTimeout(() => {
          //   for (let i = 0; i < faderChildren.length ; i++) {
          //     faderChildren[i].classList.remove('appear');
          //   }
          //   for (let i = 0; i < slidingChildren.length ; i++) {
          //       slidingChildren[i].classList.remove('appear');
          //   }
          //   for (let i = 0; i < scalingChildren.length ; i++) {
          //     scalingChildren[i].classList.remove('appear');
          //   }
          //   for (let i = 0; i < diagonalFadeingChildren.length ; i++) {
          //       diagonalFadeingChildren[i].classList.remove('appear');
          //   }
          // }, 1);
        }
      });
    },
    // handleStopScroll() {
    //   clearTimeout(this.isScrolling);
    //   this.isScrolling = setTimeout(() => {
    //     // console.log('before snap', this.scrollDirection, this.scrollProgress, this.scrollIndex)
    //     if((!isNaN(this.scrollProgress) || this.scrollProgress == 0 || this.scrollProgress == 1) && (this.scrollProgress >= .99 || this.scrollProgress <= .01) ) {
    //       // this.snapSection()
    //     }
    //   }, 250)
    // }
  },
  computed: {
    ...mapGetters(['getVSlider'])
  }
};
</script>
  
<style lang="scss" scoped>
// .panel {
//   position: fixed;
//   width: 100%;
//   height: 100%;
//   top: 20%;
//   left: 0;
// }
// .panel:not(.first) {
//   opacity: 0;
//   visibility: hidden;
//   transform: scale(0.8);
// }
// .scroll-protect {
//   height: 500rem;
// }
</style>