<template>
  <div class="container-fluid" >
    <div class="row explore-more text-start">
      <div class="col-12 align-self-center">
        <div class="row">
          <div class="col-12 col-xl-6 mb-3 d-block d-xl-none">
            <div class="horizontal-img-square">
              <img :src="section.media_url" />
            </div>
          </div>
          <div class="col-12 col-xl-6 pb-5 mb-4">
            <div class="horizontal-text fade-in">
              <div class="divider"></div>
              <div class="horizontal-text">
                <div class="explore-header my-3">
                  {{section.title}}
                </div>
                <div class="explore-subHeader my-2 my-lg-4" v-html='section.description'>
                  <!-- Samcrete Subsidiary, Is A Member Of Sami Saad Holding Which A
                  Renowned Name In The Egyptian Market That Has Been Providing
                  Nothing, But Excellence For More Than 60 Years. Strata Soil
                  Systems Is The Sole MENA Partner With The 140-Year Maccaferri,
                  The World Leader In Challenging Soil Solutions With The Civil
                  Engineering , Geotechnical And Environmental Construction
                  Markets. -->
                </div>
                  <!-- {{img1}} -->
                  <!-- {{img2}} -->
                <div class="d-lg-flex d-block mt-lg-5 mt-2">
                  <img
                    v-if='img1 && img1.image_url'
                    class="mx-3"
                    width="100"
                    height="60"
                    :src="img1.image_url"
                  />
                  <img
                    v-if='img2 && img2.image_url'
                    class="mx-3"
                    width="100"
                    height="60"
                    :src="img2.image_url"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-6 mt-5 d-none d-xl-block slide-in">
            <div class="horizontal-img-square">
              <img :src="section.media_url" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      img1: '',
      img2: '',
    }
  },
  props: ['section'],
  mounted() {
    this.$store.dispatch('loadLabels')
      .then(res => {
        this.img1 = this.$store.getters.getLabels.find(el => el.id == this.section.custom_fields.logo1);  
        this.img2 = this.$store.getters.getLabels.find(el => el.id == this.section.custom_fields.logo2);  
      })
  }
}
</script>