<template>
  <div class="container-fluid py-5">
    <!-- <h5 class="project-subTitle my-5 text-center">
      Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Praesent Ac Tortor Venenatis,
      <br/>Malesuada Sapien Ut, Cursus Nunc. Donec Ornare Metus Vel Molestie Sagittis. Nulla At
    </h5> -->
    <!-- {{projects}} -->
    <div
      class="
        row
        custom-checkboxes
        justify-content-between
        align-items-center
        mt-5
      "
    >
      <div class="col-md-4 col-12 mb-3">
        <h3 class="project-title text-center">
          Strata {{ projectsTitle }} Projects
        </h3>
      </div>
      <div class="col-md-8 col-12">
        <div class="row" v-if="categories.length">
          <div class="swiper-btns col-1 pointer" @click="swiperPrev">
            <font-awesome-icon icon="chevron-left" class="text-pri my-2" />
          </div>
          <Swiper
            class="swiper col-10"
            ref="catSwiper"
            :options="catSwiperOptions"
          >
            <SwiperSlide class="col-4 col-lg-3 custom-radio-container mx-3">
              <label class="container">
                <span class="banner-text">All</span>
                <input type="radio" v-model="chosenCategory" :value="0" />
                <span class="checkmark"></span>
              </label>
            </SwiperSlide>
            <SwiperSlide
              class="col-6 col-lg-4 custom-radio-container mx-3"
              v-for="cat in categories"
              :key="cat.id"
              v-show="cat.projects_count != 0"
            >
              <label class="container pe-2">
                <span class="banner-text">{{ cat.title }}</span>
                <input type="radio" v-model="chosenCategory" :value="cat.id" />
                <span class="checkmark"></span>
              </label>
            </SwiperSlide>
          </Swiper>
          <div class="swiper-btns col-1 pointer" @click="swiperNext">
            <font-awesome-icon icon="chevron-right" class="text-pri my-2" />
          </div>
        </div>
        <no-data v-else :data-name="projectsTitle + ' Projects'"></no-data>
      </div>

      <!-- <div class="col-12 d-block d-lg-flex justify-content-center">
         
        <div 
          class="custom-radio-container mx-3"
          v-for='cat in categories'
          :key='cat.id'
        >
          <label class="container">
            <span class="banner-text">{{cat.name}}</span>
            <input type="radio" v-model="chosenCategory" :value="cat.id">
            <span class="checkmark"></span>
          </label>
        </div>
      </div> -->
    </div>
    <div
      class="row justify-content-center"
      v-if="categories && categories.length"
    >
      <div
        class="col-12 col-lg-10 col-xl-10 position-relative"
        :style="{ 'min-height': loading ? '400px' : '' }"
      >
        <!-- <div class="row justify-content-around">
          <div 
            class="col-12 col-xl-4 d-flex justify-content-around  pointer "
            v-for='(project, projectInd) in projects'
            :key='projectInd'  
          >
            <div class="project-card my-5 border-eff scale-eff p-md-3" @click='openModal(project)'>
              <div class="project-card-img">
                <img :src='project.img' />
                <div class="category-row">
                  <div class="badge-sec mx-1" v-if='project.featured'>featured</div>
                  <div class="badge-pri mx-1" v-if='project.category'>{{project.category}}</div>
                </div>
              </div>
              <div class="project-card-body">
                <div class="menu-text mt-4">{{project.title}}</div>
                <div class="project-year mb-4">{{project.year}}</div>
                <div class="project-category my-4">{{project.category}}</div>
                <div class="project-description">{{project.description}}</div>
              </div>
            </div>
          </div>
        </div> -->
        <Swiper class="swiper" ref="projectsSwiper" :options="swiperOption">
          <SwiperSlide
            v-for="(project, projectInd) in projects"
            :key="projectInd"
            class="col-12 col-md-6 col-lg-5 col-xl-4"
            v-show="
              chosenCategory != 0 ? project.category_id == chosenCategory : true
            "
          >
            <div class="d-flex justify-content-center pointer diagonal-fade-in">
              <div
                class="project-card my-2 my-md-3 border-eff p-md-3"
                @click="openModal(project)"
              >
                <div class="project-card-img">
                  <img
                    v-if="
                      project.featured_image_url &&
                      project.featured_image_url.split('/')[
                        project.featured_image_url.split('/').length - 1
                      ] != 'null'
                    "
                    :src="project.featured_image_url"
                  />
                  <img
                    v-else-if="project.images && project.images.length > 0"
                    :src="project.images[0] && project.images[0].url"
                  />
                  <img v-else class="holder-project-image" :src="placeHolder" />
                  <div class="category-row">
                    <div class="badge-sec mx-1" v-if="project.featured">
                      featured
                    </div>
                    <div v-if="project.labels" class="d-flex">
                      <div
                        class="badge-pri mx-1"
                        v-for="(label, i) in project.labels"
                        :key="i"
                        :style="{ backgroundColor: label.color }"
                      >
                        {{ label.title }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="project-card-body">
                  <div class="menu-text project-title-text mt-2 mt-md-4">
                    {{ project.titles }}
                  </div>
                  <!-- <div class="project-year mb-4">{{project.year}}</div>
                  <div class="project-category my-4">{{project.category}}</div>
                  <div class="project-description">{{project.description}}</div> -->
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        <PreLoader v-if="loading" />
        <div class="swiper-actions" v-if="projects.length != 0 && !loading">
          <span class="btn-icon-pri shadow-eff" @click="ProjectsSwiperPrev">
            <font-awesome-icon
              :icon="['fa', 'chevron-left']"
            ></font-awesome-icon>
          </span>
          <span class="btn-icon-pri shadow-eff" @click="ProjectsSwiperNext">
            <font-awesome-icon
              :icon="['fa', 'chevron-right']"
            ></font-awesome-icon>
          </span>
        </div>
      </div>
    </div>
    <no-data
      v-if="projects.length == 0"
      :data-name="projectsTitle + ' Projects'"
    ></no-data>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { mapActions, mapGetters } from "vuex";
import PreLoader from "@/components/misc/ProjectLoader.vue";
import NoData from "@/components/misc/NoData.vue";
export default {
  components: {
    Swiper,
    SwiperSlide,
    PreLoader,
    NoData,
  },
  data() {
    return {
      placeHolder: require("../assets/images/logos/Group 17369@2x.png"),
      openedModal: {},
      loading: false,
      chosenCategory: 0,
      projects: [],
      modalCards: [
        {
          label: "Client Name",
          icon: { icon: "user", type: "far" },
          value: "Strata",
        },
        { label: "Value", icon: "EGP", value: "$ 1,000,000" },
        {
          label: "Duration",
          icon: { icon: "clock", type: "far" },
          value: "1 year",
        },
      ],
      swiperOption: {
        speed: 1000,
        // grabCursor: true,
        // centeredSlides: true,
        slidesPerView: "auto",
        spaceBetween: 0,
        // loop: true,
      },
      catSwiperOptions: {
        speed: 1000,
        grabCursor: true,
        slidesPerView: "auto",
        // spaceBetween: 20,
      },
      categories: [],
    };
  },
  props: ["projectsTitle", "openModal", "closeModal", "myModalShow"],
  mounted() {
    this.loadFilteredProjects();
    this.loadCategories()
      .then((res) => {
        if (res.status == 200) {
          this.categories = this.getCategories.filter((el) => {
            if (el.parent_id && el.parent_id != null) {
              if (this.$route.name == "Foundations") {
                return el.parent_id == 12 ? el : "";
              } else {
                return el.parent_id == 11 ? el : "";
              }
            }
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  },
  methods: {
    ...mapActions(["loadProjects", "loadCategories"]),
    loadFilteredProjects(id) {
      this.loading = true;
      this.loadProjects({ id: id, status: 1, limit: 100 })
        .then((res) => {
          if (res.status == 200 && this.getProjects != null) {
            this.projects = this.getProjects.filter((projects) => {
              if (this.projectsTitle == "Foundations") {
                return projects.category.parent_id == 12;
              } else {
                return projects.category.parent_id == 11;
              }
            });
            console.log(res);
            this.loading = false;

            this.$forceUpdate();
            if (this.projects == this.getProjects) {
              this.loading = false;
            }
          }
        })
        .catch((err) => {
          console.error(err);
          this.loading = false;
        });
    },
    swiperNext() {
      this.catSwiper.slideNext(500);
    },
    swiperPrev() {
      this.catSwiper.slidePrev(500);
    },
    ProjectsSwiperNext() {
      this.projectsSwiper.slideNext(500);
    },
    ProjectsSwiperPrev() {
      this.projectsSwiper.slidePrev(500);
    },
  },
  computed: {
    ...mapGetters(["getProjects", "getCategories"]),
    catSwiper() {
      return this.$refs.catSwiper.$swiper;
    },
    projectsSwiper() {
      return this.$refs.projectsSwiper.$swiper;
    },
  },
};
</script>


<style lang="scss" scoped>
// .swiper-img{
//   img {
//     width: auto;
//     height: 350px;
//   }
// }
.fixed-height {
  height: 450px;
}
.slide-photo {
  object-fit: cover;
  height: 450px;
}
.swiper-actions {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  pointer-events: none;

  span {
    padding: 15px 18px;
    color: #fff;
    margin: 0 3px;
    z-index: 100;
    pointer-events: initial;
    position: relative;
    cursor: pointer;
    @media (max-width: 400px) {
      padding: 10px 12px !important;
    }
    &:last-child {
      position: absolute;
      right: 0 !important;
      @media (max-width: 992px) {
        right: -43px;
      }
    }
  }
}
</style>







