<template>
  <div class="row h-100">
    <div
      class="col-12 mt-lg-0"
      :class="width == 'sm' ? 'align-self-center' : 'align-self-center'"
    >
      <div class="map-canvas">
        <img src="./../assets/images/sections/Soil System Map.jpg" class="" />
        <div
          class="point-container"
          :style="{
            left: width == 'sm' ? point.postion.left -1 + '%' : point.postion.left + '%',
            top: width == 'sm' ? point.postion.top -7 + '%' : point.postion.top + '%',
          }"
          v-for="point in points"
          :key="point.id"
        >
          <div
            class="popover__wrapper"
            :class="width == 'sm' ? 'popover__wrapper-mob' : ''"
          >
            <div class="pointer">
              <button
                class="point btn-icon-point"
                type="button"
                @click="openFixedPopover(point)"
              >
                <svg-transition
                  class=""
                  trigger="hover"
                  :size="{ height: 16, width: 16 }"
                >
                  <font-awesome-icon
                    :icon="['far', 'dot-circle']"
                    slot="initial"
                  />
                  <font-awesome-icon :icon="['fas', 'circle']" />
                </svg-transition>
              </button>
            </div>
            <div
              v-if="width != 'sm'"
              class=""
              :id="`popover-wrapper-${point.id}`"
              :class="[
                point.postion.top < 50
                  ? 'popover__content'
                  : 'popover__content-top',
              ]"
            >
              <div class="">
                <div class="p-4">
                  <div class="point-card-title my-3">{{ point.titles }}</div>
                  <div class="point-card-desc my-3 popper-content-text">
                    {{ point.descriptions }}
                  </div>
                  <div class="more-details my-3 text-start pointer" @click='openLink(point.details_url)'>More details</div>
                </div>
                <div class="point-card-icons p-3 px-4 pt-4 text-center">
                  <div class="pointer" @click="openGallery(point)">
                    <font-awesome-icon :icon="['fas', 'th-large']" size="lg" />
                    <div class="point-card-desc mt-2">Gallery</div>
                  </div>
                  <div class="pointer" @click="openLink(point.video_url)">
                    <font-awesome-icon :icon="['fas', 'play']" size="lg" />
                    <div class="point-card-desc mt-2">Video</div>
                  </div>
                  <div class="pointer" @click="goProjects(point.category)">
                    <font-awesome-icon
                      :icon="['fas', 'location-arrow']"
                      size="lg"
                    />
                    <div class="point-card-desc mt-2">Projects</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <button 
          v-show='point.position.top > 50'
          class="point btn-icon-point" 
          v-popover.top="{name: point.id}"
          :style="{left: point.position.left+'%', top: point.position.top+'%'}"
          v-for='point in points'
          :key="point.id + 't'"
        >
          <svg-transition 
            class="" 
            trigger='hover' 
            :size="{ height: 16, width: 16 }"
          >
            <font-awesome-icon  :icon="['far', 'dot-circle']" slot="initial"/>
              
          </svg-transition>   
        </button>
        <button 
          v-show='point.position.top < 50'
          class="point btn-icon-point" 
          v-popover.bottom="{name: point.id}"
          :style="{left: point.position.left+'%', top: point.position.top+'%'}"
          v-for='point in points'
          :key="point.id"
        >
          <svg-transition 
            class="" 
            trigger='hover' 
            :size="{ height: 16, width: 16 }"
          >
            <font-awesome-icon  :icon="['far', 'dot-circle']" slot="initial"/>
            <font-awesome-icon :icon="['fas', 'circle']"  />
          </svg-transition>   
        </button> -->
      </div>
    </div>
    <div
      class="point-container-mob col-12"
      id="mobileWrapper"
      v-show="width == 'sm' && chosenPoint != false"
    >
      <div class="p-2 py-3">
        <div class="point-card-title my-2">{{ chosenPoint.titles }}</div>
        <div class="point-card-desc my-2 popper-content-text">{{ chosenPoint.descriptions }}</div>
        <div class="more-details my-2 text-start pointer" @click='openLink(chosenPoint.details_url)'>More details</div>
      </div>
      <div class="point-card-icons p-3 pt-2 text-center">
        <div class="" @click="openGallery(chosenPoint)">
          <font-awesome-icon :icon="['fas', 'th-large']" />
          <div class="point-card-desc mt-2">Gallery</div>
        </div>
        <div class="pointer" @click="openLink(chosenPoint.video_url)">
          <font-awesome-icon :icon="['fas', 'play']" />
          <div class="point-card-desc mt-2">Video</div>
        </div>
        <div class="" @click="goProjects(chosenPoint.category)">
          <font-awesome-icon :icon="['fas', 'location-arrow']" />
          <div class="point-card-desc mt-2">Projects</div>
        </div>
      </div>
    </div>
    <h4
      class="explore-header px-5 text-center"
      v-show="width == 'sm' && chosenPoint == false"
    >
      Touch a Point on the map to Show its details
    </h4>
    <!-- <popover 
      v-for='point in points'
      :key='point.id'
      :name="point.id"
      :pointer="true"
      :width="270"
      event="click" 
      transition="fade" 
    >
      <div class=" p-4">
        <div class="point-card-title my-3 ">{{point.title}}</div>
        <div class="point-card-desc my-3 ">{{point.desc}}</div>
        <div class="more-details my-3 text-start">More details</div>
      </div>
      <div class="point-card-icons p-3 px-4 pt-4 text-center">
        <div class="">
          <font-awesome-icon  :icon="['fas', 'th-large']" size='lg'/>
          <div class="point-card-desc mt-2"> Gallery </div>
        </div>
        <div class="">
          <font-awesome-icon  :icon="['fas', 'play']" size='lg'/>
          <div class="point-card-desc mt-2">Video</div>
        </div>
        <div class="">
          <font-awesome-icon  :icon="['fas', 'location-arrow']" size='lg'/>
          <div class="point-card-desc mt-2">Projects</div>
        </div>
      </div>
    </popover> -->
  </div>
</template>

<script>
import "intersection-observer";
import Scrollama from "vue-scrollama";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    Scrollama,
  },
  data() {
    return {
      chosenPoint: false,
      pictures: [
        require("../assets/images/slider/Image.jpg"),
        require("../assets/images/slider/Background.jpg"),
        require("../assets/images/slider/NoPath.jpg"),
        require("../assets/images/slider/construction-workers-fabricating-steel-reinforcement-bar-construction-site.jpg"),
      ],
      points: [],
    };
  },
  props: ["width", "openModal", "changeForceScroll"],
  methods: {
    ...mapActions(["loadSolutions"]),
    openFixedPopover(point) {
      this.chosenPoint = { ...point };
      let otherPopovers = document.querySelectorAll(".popover__content");
      let otherPopoversTop = document.querySelectorAll(".popover__content-top");
      try {
        otherPopovers.forEach((el) => {
          el.classList.remove("active");
        });
        otherPopoversTop.forEach((el) => {
          el.classList.remove("active");
        });
        let mobEle = document.getElementById("mobileWrapper");
        mobEle.classList.add("appear");
        let ele = document.getElementById(`popover-wrapper-${point.id}`);
        ele.classList.toggle("active");
      } catch {
        console.warn("unimportant Error In Solutions Points Section");
      }
    },
    openLink(link) {
      console.log(link);
      window.open(link, "_blank");
    },
    scrollToProjects() {
      this.changeForceScroll(true);
      let ele = document.getElementById("section-3");
      ele.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    },
    openGallery(point) {
      console.log(point);
      if(point.images && point.images.length > 0) {
        this.openModal(point.images, point.id);
      }
    },
    goProjects(category) {
      this.$router.push({
        name: 'Projects',
        params: {
          id: category.id
        }
      })
    }
  },
  mounted() {
    this.loadSolutions()
      .then((res) => {
        if (res.status == 200) {
          this.points = res.data.results;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>