import Vuex from "vuex";
import Vue from "vue";
import testimonials from "./testimonials";
import sliders from "./sliders";
import banners from "./banners";
import contsctUs from "./contact-us";
import projects from "./projects";
import categories from "./categories";
import pages from "./pages";
import subscribe from "./subscribe";
import jobs from "./jobs";
import jobCategories from "./jobCategories";
import labels from "./labels";
import sections from "./sections";
import solutions from "./solutions";
import settings from "./settings";
Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        sliders,
        testimonials,
        banners,
        contsctUs,
        projects,
        categories,
        pages,
        subscribe,
        jobs,
        jobCategories,
        labels,
        sections,
        solutions,
        settings
    }
});