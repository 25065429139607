<template>
  <div class="contact-us-container justify-content-center row mb-3">
    <div class="col-12 col-xl-10">
      <!-- <div class="my-5 explore-header text-center">
        Keep In Touch
        <div class="divider my-3 mx-auto"></div>
      </div> -->
      <div class="contact-us-panels row">

        <div
          class="contact-us-panel col my-2 mx-3 mx-xl-4"
          v-for="(panel, panelInd) in panels"
          :key="panelInd"
        >
          <div class="row w-100 text-center">
            <div class="contact-us-icon-container col-3">
              <font-awesome-icon class="contact-us-icon" :icon="panel.icon" />
            </div>
            <div class="contact-us-text align-self-center footer-contacts-contact-us col-9">
              <a
                v-for="(item, itemInd) in panel.data"
                :key="itemInd"
                class="footer-links-text"
                :href='panel.href'
              >
                {{ item }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      panels: [
        {
          id: 1,
          icon: ["fas", "map-marker-alt"],
          data: ["8 El-Mansoura Road, EL Haram, Giza"],
          href: `https://goo.gl/maps/UuEN7Kb25inPPMqJ7`,
        },
        {
          id: 2,
          icon: ["fas", "phone"],
          data: ["212 / 33811111", "212 / 33811100"],
          href: 'tel:212 / 33811111',
        },
        {
          id: 3,
          icon: ["fa", "envelope"],
          data: ["www.strata-eg.com", "info@strata-eg.com"],
          href: 'mail-to',
        },
      ],
    };
  },
  mounted( ){
    this.$store.dispatch('loadSettings', {})
      .then(res => {
        this.panels.forEach((el, elind) => {
          if(elind == 0) {
            el.data = [ 
              this.settings.address 
            ];
            el.href= `http://maps.google.com/?q=strata`;
          }else if( elind == 1) {
            el.data = [
              this.settings.phone
            ];
            el.href= `tel:${this.settings.phone}`;
          }else if (elind ==2) {
            el.data = [
              this.settings.email
            ];
            el.href= `mailto:${this.settings.email}`;
          }
        })
      })

  },
  computed: {
    settings() {
      return this.$store.getters.getSettings;
    }
  }
};
</script>