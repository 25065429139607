<template>
  <div class="row">
    <div class="col-12 align-self-center">
      <div class="d-block text-start mx-9 px-4">
        <!-- <div class="divider mb-3 slide-in"></div> -->
        <!-- <div class="explore-header px-md-0">
            Strata's <br /> 
            Sister
            <br /> Companies
          </div> -->
      </div>
      <div class="partners-section px-4">
        <div class="align-self-center px-lg-4 d-flex">
          <div class="d-none d-lg-block mt-3">
            <button
              class="btn-icon-pri-lg shadow-eff"
              type="button"
              @click="swiper.slidePrev()"
            >
              <font-awesome-icon :icon="['fas', 'chevron-left']" />
            </button>
          </div>
          <Swiper ref="mySwiper" class="swiper" :options="swiperOptions">
            <SwiperSlide
              class="col-10 col-sm-8 col-md-5 col-lg-4 text-center"
              :class="logo.class"
              v-for="(logo, logoInd) in partners"
              :key="logoInd"
            >
              <img
                class=""
                :src="logo.src"
                :width="logo.width"
                :height="logo.height"
              />
            </SwiperSlide>
          </Swiper>
          <div class="d-none d-lg-block mt-3">
            <button
              class="btn-icon-pri-lg shadow-eff"
              type="button"
              @click="swiper.slideNext()"
            >
              <font-awesome-icon :icon="['fas', 'chevron-right']" />
            </button>
          </div>
        </div>
        <div class="d-block d-lg-none mt-5">
          <span class="m-3">
            <button
              class="btn-icon-pri shadow-eff"
              type="button"
              @click="swiper.slidePrev()"
            >
              <font-awesome-icon :icon="['fas', 'chevron-left']" />
            </button>
          </span>
          <span class="m-3">
            <button
              class="btn-icon-pri shadow-eff"
              type="button"
              @click="swiper.slideNext()"
            >
              <font-awesome-icon :icon="['fas', 'chevron-right']" />
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
  data() {
    return {
      swiperOptions: {
        speed: 1300,
        centeredSlides: true,
        slidesPerView: "auto",
        spaceBetween: 25,
        grabCursor: true,
        loop: true,
      },
      partners: [
        {
          src: require("../../src/assets/images/logos/22.png"),
          width: "230",
          height: "100",
        },
        {
          src: require("../../src/assets/images/logos/33.png"),
          width: "200",
          height: "100",
        },
        {
          src: require("../../src/assets/images/logos/44.png"),
          width: "230",
          height: "100",
        },
        {
          src: require("../../src/assets/images/logos/55.png"),
          width: "230",
          height: "100",
        },
        {
          src: require("../../src/assets/images/logos/66.png"),
          width: "200",
          height: "100",
        },
        {
          src: require("../../src/assets/images/logos/77.png"),
          width: "180",
          height: "100",
        },
        {
          src: require("../../src/assets/images/logos/888.png"),
          width: "180",
          height: "100",
        },
        // {src: require('../../src/assets/images/logos/99.png'), width: '180', height: '100'},
        // {src: require('../../src/assets/images/logos/100.png'), width: '180', height: '100'},
        // {src: require('../../src/assets/images/logos/112.png'), width: '180', height: '100'},
        {
          src: require("../../src/assets/images/logos/113.png"),
          width: "180",
          height: "100",
        },
        {
          src: require("../../src/assets/images/logos/114.png"),
          width: "180",
          height: "100",
        },
        {
          src: require("../../src/assets/images/logos/Untitled-1.png"),
          width: "180",
          height: "100",
        },
      ],
      swiperInterval: "",
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  watch: {
    scrollIndex: function (newInd, oldInd) {
      if (newInd !== oldInd && newInd != 3) {
        // clearInterval(interval)
      } else {
        // this.swiperInterval =
        //   setInterval(() => {
        //     this.swiper.slideNext();
        //   }, 3500);
      }
    },
  },
  props: ["scrollIndex"],
  mounted() {
    this.swiperInterval = setInterval(() => {
      this.swiper.slideNext();
    }, 3500);
    // this.swiperInterval();
  },
  destroyed() {
    clearInterval(this.swiperInterval);
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
};
</script>
swiper.slideNext()