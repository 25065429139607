import Axios from "axios";

export default {
    state: {
        settings: null,
    },
    mutations: {
        setSettings(state, settingsPayload) {
            state.settings = settingsPayload;
        },
    },
    getters: {
        getSettings(state) {
            return state.settings;
        },
    },
    actions: {
        loadSettings({ commit }, payload) {
            return new Promise((resolve, reject) => {
                Axios.post("/v1/settings").then(res => {
                    if (res.data.results != null) {
                        commit("setSettings", res.data.results);
                        resolve(res);
                    } else {
                        reject(res);
                    }
                }).catch(err => {
                    reject(err);
                });
            });
        },
    }
};