<template>
<div class="row h-100">
    <div class="col-12 align-self-center">
      <div class="row justify-content-center px-4 px-lg-1">
        <div class="col-12 col-xl-6 mt-lg-5 slide-in">
          <div class="horizontal-img-soil">
            <img :src="section.media_url" />
          </div>
        </div>
        <div
          class="
            col-12 col-xl-5
            mx-1
            mt-1
            mx-xl-5
            text-start
            soil-more
            fade-in
          "
        >
          <div class="explore-header my-5">
            <div class="divider"></div>
            {{section.title}}
          </div>
          <div class="explore-subHeader my-5 fade-in" v-html='section.description'>
            <!-- Globally, Strata is the sole MENA partner with the 140- year
            Maccaferri, the world leader in challenging soil solutions
            with the Civil Engineering, Geotechnical and Environmental
            Construction markets. Together, we have cut hundreds of
            working hours using our tailor-made solutions, dramatically
            reducing costs, and applying eco-friendly solutions. -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['section']
}
</script>