<template>
  <div class="contaienr-fluid text-start" v-if='!loading '>
    <!-- <div class="row">
      <div class="col-12 "> -->
    <div
      :id="`myCarousel${section.id}`"
      class="carousel slide carousel-fade"
      data-bs-ride="carousel"
    >
      <div class="carousel-inner" >
        <div
          class="carousel-item full-slider-slide fade-over-dark"
          :class="slideInd == 0 ? 'active' : ''"
          v-for="(slide, slideInd) in allSlides.banners"
          :key="`${slideInd}myCarousel${section.id}`"
        >
          <img :src="slide.image_url" class="d-block w-100" alt="" />
        </div>
      </div>
      <!-- <button class="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button> -->
      <div class="slide-captions fade-in" :id="`slideDescription${slideIndex}`">
        <!-- <div class="slide-header my-3">
          <font-awesome-icon
            class="mx-2"
            :icon="['far', 'dot-circle']"
            size="xs"
          /> -->
          <!-- {{ getVSlider.banners[slideIndex] }} -->
        <!-- </div> -->
        <div class="slide-text my-3">
          {{ allSlides.banners[slideIndex].title }}
        </div>
        <div class="slide-subText my-3 d-none d-md-block">
          {{ allSlides.banners[slideIndex].description }}
          
        </div>
        <a type="button" v-if="allSlides.banners[slideIndex].target && allSlides.banners[slideIndex].target.target" @click="goTarget(slide)"  class=" btn-pri">
          View More
        </a>
      </div>
      <!-- <div class="banner-updates  flex-row d-none d-md-flex">
          <div class="banner-text text-ellipsis"> Last updates </div>
          <div class="ver-divider"></div>
          <div class="text-secondary text-ellipsis"> Strata Soil Systems Is One Of 21 Subsidiary Belonging To SAMCRETE Corporation Started 5 Years Ago And Rapidly Penetrating </div>
        </div> -->
      <button
        class="btn-sec slide-button-right shadow-eff"
        type="button"
        @click="goNextSlide"
      >
        next slide
        <font-awesome-icon
          class="mx-2"
          :icon="['fas', 'arrow-right']"
          size="xs"
        />
      </button>
      <button
        
        class="btn-sec slide-button-left shadow-eff"
        type="button"
        @click="goPrevSlide"
      >
        <font-awesome-icon
          class="mx-2"
          :icon="['fas', 'arrow-left']"
          size="xs"
        />
        Previous slide
      </button>
      <!-- <div class="next-preview d-none d-sm-block" data-bs-target="#myCarousel" data-bs-slide="next">
            <transition name='preview'>
              <img class="" :key="nextSlide" :src='sliderItems[nextSlide].image'/>
            </transition>
          </div> -->
    </div>
  </div>
  <!-- </div>
  </div> -->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      carousel: "",
      myCarousel: '',
      slideDescription: '',
      loading: false,
      sliderItems: [
        // {
        //   title: "WELCOME TO STRATA 1",
        //   subTItle: "Engineering A Better Solution",
        //   description:
        //     "Strata Is One Of The 21 Samcrete Corporation-Owned Subsidiaries. Started Less Than A Decade Ago; Yet Rapidly Penetrating The Egyptian Market By Delivering Ambitious, Fast-Growing And Quality Projects.",
        //   image: require("../../src/assets/images/slider/Background.jpg"),
        // },
        // {
        //   title: "WELCOME TO STRATA 2",
        //   subTItle: "Engineering A Better Solution",
        //   description:
        //     "Strata Is One Of The 21 Samcrete Corporation-Owned Subsidiaries. Started Less Than A Decade Ago; Yet Rapidly Penetrating The Egyptian Market By Delivering Ambitious, Fast-Growing And Quality Projects.",
        //   image: require("../../src/assets/images/slider/NoPath.jpg"),
        // },
        // {
        //   title: "WELCOME TO STRATA 3",
        //   subTItle: "Engineering A Better Solution",
        //   description:
        //     "Strata Is One Of The 21 Samcrete Corporation-Owned Subsidiaries. Started Less Than A Decade Ago; Yet Rapidly Penetrating The Egyptian Market By Delivering Ambitious, Fast-Growing And Quality Projects.",
        //   image: require("../../src/assets/images/slider/Image.jpg"),
        // },
      ],
      nextSlide: 0,
      allSlides: null
    };
  },
  watch: {
    // secId: function(oldID, newID) {
    //   setTimeout(() => {
    //     this.initiateCarousel();
    //   }, 50);
    // },
  },
  props: ["width", 'section', 'secId'],
  mounted() {
    this.loading = true;
    this.initiateCarousel();
  },
  methods: {
    ...mapActions(["loadBanners", "loadSliders", 'loadVSlider']),
    initiateCarousel() {
      this.loadVSlider({
        id: this.section.custom_fields ? this.section.custom_fields.slider_id : 1,
      }).then((res) => {
        if (res.status == 200) {
            this.loading = false;
            // this.sliderItems = this.getVSlider;
            this.allSlides = res.data.results;
            let defineInt = setInterval(() => {
              this.myCarousel = document.getElementById(`myCarousel${this.section.id}`);
              this.slideDescription = document.getElementById(`myCarousel${this.section.id}`);
              if(this.myCarousel && this.slideDescription) {
                window.clearInterval(defineInt);
                let vm = this;
                vm.$loadScript("../../js/bootstrap.bundle.js").then((res) => {
                  vm.slideDescription.classList.add("appear");
                  vm.carousel = new bootstrap.Carousel(vm.myCarousel, {
                    // interval: 5000,
                    wrap: true,
                  });
                });
                vm.myCarousel.addEventListener("slide.bs.carousel", function (slider) {
                  vm.slideDescription.classList.remove("appear");
                  setTimeout(() => {
                    vm.nextSlide = slider.to;
                    vm.slideDescription.classList.add("appear");
                  }, 500);
                });
                window.clearInterval(inting);
                vm.$forceUpdate();
                var inting = setInterval(() => {
                  vm.carousel.next();
                }, 5000)
                } 
              }, 50)
          }
        })
    },
    goNextSlide() {
      console.log('next clicked', this.carousel)
      this.carousel.next();
    },
    goPrevSlide() {
      console.log('prev clicked', this.carousel )
      this.carousel.prev();
    },
    goTarget(slide) {
      if(slide.target && slide.target.target) {
        window.open(slide.target.target)
      }
    }
  },
  computed: {
    ...mapGetters(["getSliders", 'getVSlider']),
    slideIndex() {
      return this.nextSlide;
    },
  },
};
</script>

<style lang="scss" scoped>
.preview-enter-active {
  transition: all 0.7s ease-in;
  // background: red;
}
.preview-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}
.preview-enter, .preview-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>