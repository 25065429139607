import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';



Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: {
      path: '/home'
    }
    // component: Home
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  // {
  //   path: '/foundations',
  //   name: 'Foundations',
  //   component: () => import(/* webpackChunkName: "foundations" */ '../views/FoundationsLayout.vue'),
  //   // meta: {
  //   //   transition: 'fade-in-down'
  //   // }
  // },
  // {
  //   path: '/soil-systems',
  //   name: 'SoilSystems',
  //   component: () => import(/* webpackChunkName: "soil" */ '../views/SoilSystems.vue'),
  //   // meta: {
  //   //   transition: 'fade-in-down'
  //   // }
  // },
  {
    path: '/projects/:id',
    name: 'Projects',
    component: () => import(/* webpackChunkName: "soil" */ '../views/Projects.vue'),
  },
  {
    path: '/hiring',
    name: 'Hiring',
    component: () => import(/* webpackChunkName: "soil" */ '../views/Hiring.vue'),
    // meta: {
    //   transition: 'fade-in-down'
    // }
    children: [
      {
        path: ":id",
        name: "ViweJob",
        component: () => import("../components/misc/ViwedJob.vue")
      }
    ]
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: () => import(/* webpackChunkName: "soil" */ '../views/ContactUs.vue'),
    // meta: {
    //   transition: 'fade-in-down'
    // }
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

export default router;
