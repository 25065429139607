<template>
  <header
    class="sticky-top"
    :class="sticky == true ? 'sticky-show' : 'sticky-hidden'"
    id="headerEle"
  >
    <nav
      class="navbar navbar-expand-md app-header"
      :class="
        $route.name != 'Home' || secondary == true
          ? 'app-header-sec'
          : 'app-header-home'
      "
      id="mainNav"
    >
      <div class="container-fluid">
        <a class="navbar-brand mx-4 pointer" @click="goPage('Home')">
          <img
            :src="
              $route.name != 'Home' || secondary == true
                ? require('../../assets/images/logos/Group 16297.png')
                : require('../../assets/images/logos/Group 1.png')
            "
            width="120"
          />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          :class="$route.name != 'Home' ? 'navbar-toggler-sec' : ''"
        >
          <font-awesome-icon icon="bars" slot="initial" />
          <!-- <svg-transition :size='size'> -->
          <!-- <font-awesome-icon icon="times" /> -->
          <!-- </svg-transition> -->
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <span v-for="(link, ind) in links" :key="ind + 10">
              <li
                class="nav-item mx-4"
                @click="goPage(link.name)"
                v-if="link.label !== 'Home' || $route.name !== 'Home'"
              >
                <a
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  class="nav-link pointer nav-text underline-eff"
                  :class="[
                    $route.name == link.name ? 'nav-text-active' : '',
                    $route.name != 'Home' || secondary == true
                      ? ''
                      : 'nav-text-sec',
                  ]"
                  aria-current="page"
                >
                  {{ link.label }}
                </a>
              </li>
            </span>

            <li
              v-for="(item, itemInd) in menuItems"
              :key="itemInd"
              v-show="width == 'sm'"
              class="mx-4"
              @click="goPage(item.name)"
            >
              <a
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-current="page"
                class="nav-link nav-text pointer"
                v-if="item.label"
                :class="
                  $route.name != 'Home' || secondary == true
                    ? ''
                    : 'nav-text-sec'
                "
              >
                {{ item.label }}
              </a>
              <div
                class="nav-link menu-text d-flex justify-content-between"
                v-if="item.items"
              >
                <span
                  v-for="(icon, iconInd) in item.items"
                  :key="iconInd"
                  class="mx-3 pointer bg-eff"
                  :class="$route.name == 'Home' ? 'text-white' : ''"
                >
                  <!-- {{icon.icon}} -->
                  <font-awesome-icon :icon="['fab', icon.icon]" />
                </span>
              </div>
            </li>
          </ul>
        </div>
        <div class="dropdown float-right" v-if="false" id="myDropdown">
          <button
            :class="
              $route.name != 'Home' || secondary == true
                ? 'btn-icon-trans'
                : 'btn-icon-home'
            "
            type="button"
            id="dropdownMenuLink"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <svg-transition :size="size">
              <font-awesome-icon icon="bars" slot="initial" />
              <font-awesome-icon icon="times" />
            </svg-transition>
          </button>

          <ul
            class="dropdown-menu dropdown-menu-end menu-list"
            aria-labelledby="dropdownMenuLink"
          >
            <li v-for="(item, itemInd) in menuItems" :key="itemInd + 10">
              <a
                class="dropdown-item menu-item menu-text pointer"
                v-if="item.label"
              >
                {{ item.label }}
              </a>
              <div
                class="
                  dropdown-item
                  menu-item menu-text
                  d-flex
                  justify-content-between
                "
                v-if="item.items"
              >
                <span
                  v-for="(icon, iconInd) in item.items"
                  :key="iconInd * 10"
                  class="mx-3 pointer bg-eff"
                >
                  <!-- {{icon.icon}} -->
                  <font-awesome-icon :icon="['fab', icon.icon]" size="xs" />
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  data() {
    return {
      headerShow: false,
      links: [
        // { name: "Home", label: "Home" },
        // { name: "Foundations", label: "Foundations" },
        // { name: "SoilSystems", label: "Soil Systems" },
        // {name: 'Hiring', label: 'We Are Hiring'},
        // {name: 'ContactUs', label: 'Contact Us'},
      ],
      size: {
        width: "24",
        height: "20",
      },
      menuItems: [
        { name: "ContactUs", label: "Contact us" },
        { name: "Hiring", label: "We Are Hiring" },
        {
          name: "social",
          items: [
            { name: "linkedin", icon: "linkedin-in" },
            { name: "facebook", icon: "facebook-f" },
            { name: "instagram", icon: "instagram" },
            { name: "twitter", icon: "twitter" },
            { name: "youtube", icon: "youtube" },
          ],
        },
      ],
      collapsableNav: "",
    };
  },
  watch: {
    // headerShowClass: function(newShow, oldShow) {
    //   console.log(newShow)
    // },
  },
  props: ["width", "secondary", "sticky", "secId", "changeForceScroll"],
  mounted() {
    this.controlledRoutes.forEach(el => {
      this.links.push({name: el, label: el});
    })
    const headerEle = document.getElementById("headerEle");
    headerEle.onmouseover = () => {
      headerEle.style.opacity = "1";
    };
    headerEle.onmouseleave = () => {
      if(!this.contactRoutes && this.width != 'sm') {
        setTimeout(() => {
          headerEle.style.opacity = "0";
        }, 2000);
      }
    };
    // window.addEventListener('scroll', this.CloseNav);
    this.collapsableNav = document.getElementById("navbarSupportedContent");
    // var myDropdown = document.getElementById('myDropdown')
    // myDropdown.addEventListener('show.bs.dropdown', function () {
    //   document.getElementsByClassName('.dropdown-menu').first().stop(true, true).slideDown(300);
    // })
    // myDropdown.addEventListener('hide.bs.dropdown', function () {
    //   document.getElementsByClassName('.dropdown-menu').first().stop(true, true).slideDown(200);
    // })
  },
  destroyed() {
    // window.removeEventListener('scroll');
  },
  methods: {
    goPage(name) {
      if (this.$route.name == name) {
        this.changeForceScroll(true);
        window.scrollTo(0, 0);
      } else {
        this.$router.push({
          name: name,
          params: {},
        });
      }
      if (this.width == "sm") {
        this.CloseNav();
      }
    },

    CloseNav() {
      // new bootstrap.Collapse(this.collapsableNav)
      this.collapsableNav.collapse();
    },
  },
  computed: {
    ...mapGetters(['controlledRoutes']),
    contactRoutes() {
      return this.$route.name == "Hiring" || this.$route.name == "ContactUs";
    },
    // headerShowClass() {
    //   let element = document.getElementById('headerEle');
    //   console.log(element.classList.contains('sticky-show'));
    //   return element.classList.contains('sticky-top')
    // }
    
  },
};
</script>

<style>
.droplist-enter {
  background: red;
  transition: all 1s ease-in;
}
.droplist-enter-active {
  background: green;
  transition: all 1s ease-in;
}
div.dropdown > .dropdown-menu {
  display: inherit;
  /* transform: translate(69px, 19px) scaleX(0); */
  transition: all 0.8s cubic-bezier(0, -0.14, 0.27, 1.55);
  /* transform-origin:left; */
  /* left: -350; */
}
</style>